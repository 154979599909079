import React, { useState } from "react";
import { axiosInstance } from "../../../utils/axiosInstance";
import { endPoints } from "../../../utils/endPoints";
import TagsInput from "../../settings/tagsInput/TagsInput";
import { PiArrowClockwiseFill } from "react-icons/pi";
import { images } from "../../../utils/images";
import Popup from "../../popup/Popup";
import {addStudent} from "../../../utils/apis/Students";

export const AddStudentForm = ({
                                 subjects,
                                 grades,
                                 handleCancel,
                                 setIsLoading,
                                handleSubmitCancel
                               }) => {
  const [formData, setFormData] = useState({
    name: "",
    lname: "",
    email: "",
    gender: "",
    grade: "",
    dateOfBirth: "",
    joinedDate: "",
    passwordHash: "",
    phoneNumber: "",
    subjects: [],
    parentDetails: {
      name: "",
      phoneNumber: "",
      email: "",
    },
    picture: "",
    syllabus: "",
    type: "",
    role: 3004,
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [subjectsError, setSubjectsError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleParentDetailsChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      parentDetails: {
        ...prevFormData.parentDetails,
        [name]: value,
      },
    }));
  };

  const generatePassword = () => {
    const password = Math.random().toString(36).slice(-8);
    setFormData({ ...formData, passwordHash: password });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (formData.subjects.length === 0) {
      setSubjectsError(true);
      setPopupText("Please add at least one subject.");
      setShowPopup(true);
      return;
    }
    if (!formData.passwordHash) {
      setPopupText("Please generate a password before submitting.");
      setShowPopup(true);
      return;
    }
    setSubjectsError(false);
    addStudent(setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)
  };

  return (
      <>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="cols">
            <div className="form-first-column">
              <label>
                Name:*
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
              </label>
              <label>
                Last Name:*
                <input
                    type="text"
                    name="lname"
                    value={formData.lname}
                    onChange={handleChange}
                    required
                />
              </label>
              <label>
                Email:*
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
              </label>
              <label>
                Current Grade:*
                <input
                    list="grade-list"
                    type="text"
                    name="grade"
                    value={formData.grade}
                    onChange={handleChange}
                />
                <datalist id="grade-list">
                  {grades.map((grade) => (
                      <option key={grade._id} value={grade.grade} />
                  ))}
                </datalist>
              </label>
              <label>
                Gender:*
                <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </label>
              <label>
                Date of Birth:
                <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                />
              </label>
              <label>
                Phone Number:*
                <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                />
              </label>
            </div>

            <div className="form-second-column">
              <label>
                Syllabus:*
                <select
                    name="syllabus"
                    value={formData.syllabus}
                    onChange={handleChange}
                    required
                >
                  <option value="">Select Syllabus</option>
                  <option value="Edexcel">Edexcel</option>
                  <option value="Cambridge">Cambridge</option>
                  <option value="AQA">AQA</option>
                </select>
              </label>
              <label>
                Type:*
                <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    required
                >
                  <option value="">Select Type</option>
                  <option value="Local">Local</option>
                  <option value="International">International</option>
                </select>
              </label>
              <label>
                Joined Date:
                <input
                    type="date"
                    name="joinedDate"
                    value={formData.joinedDate}
                    onChange={handleChange}
                />
              </label>
              <label>
                Password:*
                <div className="password-container student">
                  <input
                      type="text"
                      name="passwordHash"
                      value={formData.passwordHash}
                      readOnly
                      required
                  />
                  <PiArrowClockwiseFill
                      onClick={generatePassword}
                      className="password-icon"
                  />
                </div>
              </label>
              <label className="subjects">
                <p>Subjects:*</p>
                <TagsInput
                    tags={formData.subjects}
                    setTags={(newTags) => {
                      setFormData({ ...formData, subjects: newTags });
                      if (newTags.length > 0) {
                        setSubjectsError(false);
                      }
                    }}
                    subjects={subjects}
                    editable={true}
                    grades={grades}
                />
                {formSubmitted && subjectsError && (
                    <span className="error-message">
                  Please create at least one subject tag.
                </span>
                )}
              </label>
              <label>
                Parent Name:
                <input
                    type="text"
                    name="name"
                    value={formData.parentDetails.name}
                    onChange={handleParentDetailsChange}
                />
              </label>
              <label>
                Parent Phone Number:
                <input
                    type="text"
                    name="phoneNumber"
                    value={formData.parentDetails.phoneNumber}
                    onChange={handleParentDetailsChange}
                />
              </label>
              <label>
                Parent Email:
                <input
                    type="email"
                    name="email"
                    value={formData.parentDetails.email}
                    onChange={handleParentDetailsChange}
                />
              </label>
            </div>
          </div>
          <div className="btns">
            <button onClick={handleCancel}>Cancel</button>
            <button type="submit" className="full-width">
              Save
            </button>
          </div>
        </form>
        <Popup
            show={showPopup}
            text={popupText}
            onClose={() => setShowPopup(false)}
        />
      </>
  );
};
