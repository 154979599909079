// Handles input changes for syllabus fees and other grade-related data
export const handleChange = (e, formData, setFormData, gradeIndex, syllabusIndex) => {
    const { name, value } = e.target;
    if (!formData.grades || !formData.grades[gradeIndex] || !formData.grades[gradeIndex].fee) {
        console.error("Invalid formData structure");
        return;
    }
    const updatedGrades = formData.grades.map((grade, i) => {
        if (i === gradeIndex) {
            const updatedSyllabusData = grade.fee.map((syllabus, j) =>
                j === syllabusIndex
                    ? {
                        ...syllabus,
                        [name]: value,
                    }
                    : syllabus
            );
            return { ...grade, fee: updatedSyllabusData };
        }
        return grade;
    });
    setFormData({ ...formData, grades: updatedGrades });
};

export const addHandleChange = (e, formData, setFormData, gradeIndex, syllabusIndex, syllabusList) => {
    const { name, value } = e.target;
    const updatedGrades = formData.grades.map((grade, i) => {
        if (i === gradeIndex) {
            const updatedSyllabusData = grade.fee.map((syllabus, j) =>
                j === syllabusIndex
                    ? {
                        ...syllabus,
                        [name]: value,
                        syllabus: syllabusList[syllabusIndex],
                    }
                    : syllabus
            );
            return { ...grade, fee: updatedSyllabusData };
        }
        return grade;
    });
    setFormData({ ...formData, grades: updatedGrades });
};



  export const deleteGrade = (index, formData, setFormData) => {
    const updatedGrades = formData.grades.filter((_, i) => i !== index);
    setFormData({ ...formData, grades: updatedGrades });
  };
  
  export const handleClick = (
    label,
    index,
    formData,
    setFormData,
    setActiveButton
  ) => {
    const updatedGrades = formData.grades.map((grade, i) =>
      i === index ? { ...grade, level: label } : grade
    );
    setFormData({ ...formData, grades: updatedGrades });
    setActiveButton(label);
  };
  
export const handleCheckboxChange = (formData, setFormData, gradeIndex, syllabusIndex) => {
    const updatedGrades = formData.grades.map((grade, i) => {
      if (i === gradeIndex) {
        const updatedSyllabusData = grade.fee.map((syllabus, j) => {
          if (j === syllabusIndex) {
            if (syllabus.visible) {
              return syllabus.isNew
                ? {
                    ...syllabus,
                    visible: false,
                    localFee: "",
                    internationalFee: "",
                  }
                : { ...syllabus, visible: false };
            } else {
              return { ...syllabus, visible: true };
            }
          }
          return syllabus;
        });
        return { ...grade, fee: updatedSyllabusData };
      }
      return grade;
    });
    setFormData({ ...formData, grades: updatedGrades });
  };
  
  export const validateForm = (formData, setPopupText, setShowPopup) => {
    let isValid = true;
    let validationErrors = "";
  
    // Check if subjectName exists and is a string before trimming
    if (!formData.subjectName || !formData.subjectName.trim()) {
      validationErrors += "Subject Name is required. ";
      isValid = false;
    } else {
      formData.grades.forEach((grade) => {
        // Check if level exists and is a string before trimming
        if (!grade.level || !grade.level.trim()) {
          validationErrors += `Grade must be selected. `;
          isValid = false;
        } else if (
          !grade.fee.some(
            (fee) => fee.localFee !== "" || fee.internationalFee !== ""
          )
        ) {
          validationErrors += `Grade must have at least one fee entry. `;
          isValid = false;
        }
      });
    }
  
    if (!isValid) {
      console.log("errors", validationErrors.trim());
      setPopupText(validationErrors.trim());
      setShowPopup(true);
    }
  
    return isValid;
  };
  