import React, {useState} from "react";
import TagsInput from "../../settings/tagsInput/TagsInput";
import Popup from "../../popup/Popup";
import {editStudent} from "../../../utils/apis/Students";
import {axiosInstance} from "../../../utils/axiosInstance";
import {endPoints} from "../../../utils/endPoints";


export const EditFormStudent = ({
  student,
  grades,
  subjects,
  handleCancel,
  setIsLoading,
  handleSubmitCancel
}) => {
  const [formData, setFormData] = useState({
    _id: student?._id || "",
    stuId: student?.stuId || "",
    name: student?.name || "",
    lname: student?.lname || "",
    email: student?.email || "",
    gender: student?.gender || "",
    grade: student?.grade || "",
    dateOfBirth: student?.dateOfBirth
      ? new Date(student.dateOfBirth).toISOString().split("T")[0]
      : "",
    joinedDate:student?.joinedDate || "",
    phoneNumber: student?.phoneNumber || "",
    subjects: student?.subjects || [],
    parentDetails: {
      name: student?.parentDetails?.name || "",
      phoneNumber: student?.parentDetails?.phoneNumber || "",
      email: student?.parentDetails?.email || "",
    },
    picture: student?.picture || "",
    syllabus: student?.syllabus || "",
    type: student?.type || "",
    role: 3004,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleParentDetailsChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      parentDetails: {
        ...prevFormData.parentDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    editStudent(setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)
  };

  return (
    <>
    <form onSubmit={handleSubmit} className="form-container">
      <div className="cols">
        <div className="form-first-column">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="lname"
              value={formData.lname}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Current Grade:
            <input
              type="text"
              name="grade"
              value={formData.grade}
              onChange={handleChange}
            />
          </label>
          <label>
            Gender:
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label>
            Date of Birth:
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone Number:
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>

        <div className="form-second-column">
          <label>
            Syllabus:
            <select
              name="syllabus"
              value={formData.syllabus}
              onChange={handleChange}
              required
            >
              <option value="">Select Syllabus</option>
              <option value="Edexcel">Edexcel</option>
              <option value="Cambridge">Cambridge</option>
              <option value="AQA">AQA</option>
            </select>
          </label>
          <label>
            Type:
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Select Type</option>
              <option value="Local">Local</option>
              <option value="International">International</option>
            </select>
          </label>
          <label>
            Joined Date:
            <input
              type="date"
              name="joinedDate"
              value={formData.joinedDate}
              onChange={handleChange}
            />
          </label>
          <label>
            Subjects:
            <TagsInput
              tags={formData.subjects}
              setTags={(newTags) =>
                setFormData({ ...formData, subjects: newTags })
              }
              subjects={subjects}
              editable={true}
              grades={grades}
            />
          </label>
          <label>
            Parent Name:
            <input
              type="text"
              name="name"
              value={formData.parentDetails.name}
              onChange={handleParentDetailsChange}
            />
          </label>
          <label>
            Parent Phone Number:
            <input
              type="text"
              name="phoneNumber"
              value={formData.parentDetails.phoneNumber}
              onChange={handleParentDetailsChange}
            />
          </label>
          <label>
            Parent Email:
            <input
              type="email"
              name="email"
              value={formData.parentDetails.email}
              onChange={handleParentDetailsChange}
            />
          </label>
        </div>
      </div>
      <div className="btns">
        <button type="button" onClick={handleCancel}>
          Cancel
        </button>
        <button type="submit" className="full-width">
          Save
        </button>
      </div>
    </form>
    <Popup
        show={showPopup}
        text={popupText}
        onClose={() => setShowPopup(false)}
      />
    </>
  );
};
