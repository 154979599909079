import React from "react";
import { FaSearch } from "react-icons/fa";

function SearchContainer({ searchTerm, setSearchTerm }) {
  return (
    <div className="search-container">
      <FaSearch className="search-icon" />
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
}

export default SearchContainer;
