import {images} from "../images";

export  const calculateFee = (
    event,
    subject,
    student,
    setPopupText,
    setShowPopup
) => {
    let feePerHour = 0;
    let feeAmount = 0;

    if (!event.isTrial) {
        subject.grades.forEach((grade) => {
            if (grade.level === event.grade) {
                const feeInfo = grade.fee.find(
                    (fee) => fee.syllabus === student.syllabus
                );
                if (feeInfo) {
                    feePerHour =
                        student.type === "Local"
                            ? feeInfo.localFee
                            : feeInfo.internationalFee;
                }
            }
        });

        if (feePerHour === 0) {
            console.error(
                "Fee information not found for the provided syllabus and student type"
            );
            setPopupText("Fee not added yet. Please contact admin!");
            setShowPopup(true);
            return null;
        }

        const startTime = event.startTime.split(":");
        const endTime = event.endTime.split(":");

        const startHour = parseInt(startTime[0]);
        const startMinute = parseInt(startTime[1]);
        const endHour = parseInt(endTime[0]);
        const endMinute = parseInt(endTime[1]);

        const start = new Date(0, 0, 0, startHour, startMinute, 0);
        const end = new Date(0, 0, 0, endHour, endMinute, 0);
        let duration = (end - start) / 1000 / 60 / 60;

        if (duration < 0) {
            duration += 24;
        }

        const roundedDuration = Math.ceil(duration);
        feeAmount = feePerHour * roundedDuration;
        console.log("Calculated fee for rounded duration:", feeAmount);
    } else {
        console.log("Trial class, no fee calculation needed.");
    }

    return feeAmount;
};

export   const createRecurrenceDates = (startDate, recurrence) => {
    let dates = [startDate];
    let currentDate = new Date(startDate);
    const interval = recurrence === "4 Weeks" ? 4 : 12;
    const daysInWeek = 7;

    for (let i = 1; i < interval; i++) {
        currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i * daysInWeek);
        dates.push(currentDate);
    }

    return dates;
};

export const addImagesToUser=(formData)=>{
    const boys = images.boy;
    const girls = images.girl;

    const genderPrefix = formData.gender === "Male" ? "boy" : "girl";
    const pictureIndex = Math.floor(
        Math.random() * (formData.gender === "Male" ? boys.length : girls.length)
    );
    const pictureIdentifier = `${genderPrefix}_${pictureIndex + 1}`;

    return {
        ...formData,
        picture: pictureIdentifier,
    }
}

export const getUserImage=(user)=>{
    let profileImage = null;
    if (user.gender === "Male" && user.picture.startsWith("boy")) {
        const index = parseInt(user.picture.split("_")[1]) - 1;
        profileImage = images.boy[index];
    } else if (
        user.gender === "Female" &&
        user.picture.startsWith("girl")
    ) {
        const index = parseInt(user.picture.split("_")[1]) - 1;
        profileImage = images.girl[index];
    }
    return profileImage
}
