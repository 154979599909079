import React from "react";

const FilterComponent = ({
  filterData = {}, // Default to an empty object
  filters,
  collapsedSections,
  handleFilterChange,
  toggleSection,
  sectionTitles
}) => {
  return (
    <div className="filter-container">
      <h2>Filtered By</h2>
      {Object.keys(filterData).map((filterKey, index) => (
        <div className="filter-section" key={index}>
          <div
            className="filter-head"
            onClick={() => toggleSection(filterKey)}
            style={{ cursor: "pointer" }}
          >
            <h3>{sectionTitles[filterKey]}</h3>
            <h3>{collapsedSections[filterKey] ? "▼" : "▲"}</h3>
          </div>
          {!collapsedSections[filterKey] &&
            filterData[filterKey].map((item, idx) => (
              <div key={idx}>
                <input
                  type="checkbox"
                  id={`${filterKey}-${idx}`}
                  checked={filters[filterKey].includes(item)}
                  onChange={() => handleFilterChange(filterKey, item)}
                />
                <label htmlFor={`${filterKey}-${idx}`}>{item}</label>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default FilterComponent;
