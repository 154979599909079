import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import EnrollmentTable from "./EnrollmentTable";
import StudentGradeCard from "./StudentGradeCard";
import AdminGradeCard from "./AdminGradeCard";

function MoreInfoCourseTab({ course, onClose,user }) {
  console.log("Course data:", course);

  return (
    <div className="view-course-tab">
      <div className="view-content">
        <div className="header">
          <div className="lhs">
            <h2>All Courses</h2>
            <FaAngleRight className="greater-icon" />
            <h3>
              {course.subjectName} <span>(Code: {course.code})</span>
            </h3>
          </div>
          <IoCloseSharp className="close-icon" onClick={onClose} />
        </div>
        <div className="total-enrolled"><h3>Total Enrolled : {course.enrollCount}</h3></div>
        {!(user.role === 1000 || user.role === 3000 || user.role === 3001) ?
        <div className="content-student">
        {course.grades.map((gradeData, index) => (
            <StudentGradeCard key={index} gradeData={gradeData} />
          ))}

        </div>
            :
        <div className="content">
        {course.grades.map((gradeData, index) => (
            <AdminGradeCard key={index} gradeData={gradeData} />
          ))}      
        </div>
        }
        </div>

    </div>
  );
}

export default MoreInfoCourseTab;
