import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoClose } from "react-icons/io5";
import { FaAngleRight, FaLink } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { MdDateRange } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import { endPoints } from "../../utils/endPoints";
import { axiosInstance } from "../../utils/axiosInstance";
import CustomDropdown from "../CustomFunctions/CustomDropDown";

const AddEventModal = ({
                         onAddEvent,
                         onClose,
                         teachers,
                         students,
                         subjects,
                         grades,
                         studentConsultants,
                         user,
                       }) => {
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    student: "",
    teacher: user.role === 3002 ? user.name : "",
    studentConsultant: "",
    subject: "",
    date: new Date(),
    startTime: "",
    endTime: "",
    grade: "",
    price: "",
    isTrial: false,
    status: "pending",
  });

  const [meetingLink, setMeetingLink] = useState("");
  const [classRecursion, setClassRecursion] = useState("Does Not Repeat");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: fieldValue,
    });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date: date });
  };

  const handleTimeChange = (e, type) => {
    const { value } = e.target;
    setFormData({ ...formData, [type]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const adjustedDate = new Date(formData.date.getTime() + 5.5 * 60 * 60 * 1000);
    const teacher = teachers.find((t) => t.name === formData.teacher);
    const student = students.find((s) => s.stuId === formData.student);
    const studentConsultant = studentConsultants.find(
        (t) => t.name === formData.studentConsultant
    );

    const event = {
      ...formData,
      date: adjustedDate,
      grade: student.grade,
      teacherEmail: teacher ? teacher.email : "Unknown Email",
      studentEmail: student ? student.email : "Unknown Email",
      studentConsultantEmail: studentConsultant
          ? studentConsultant.email
          : "Unknown Email",
    };
    console.log(event);
    onAddEvent(event, classRecursion);
  };

  useEffect(() => {
    const student = students.find((s) => s.stuId === formData.student);

    if (student) {
      const title = `${student.name}'s ${formData.subject} class (${student.syllabus} ${student.grade})`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        title: title,
      }));
    }
  }, [formData.student, formData.subject, students]);


  const filteredSubjects = () => {
    if (user.role === 3002) {
      return subjects.filter((subject) =>
          user.subjects.some(
              (teacherSubject) => teacherSubject.name === subject.subjectName
          )
      );
    } else {
      const student = students.find((s) => s.stuId === formData.student);
      return student ? student.subjects : [];
    }
  };


  const filteredTeachers = () => {
    if (user.role === 3002) {
      return [user];
    } else {
      const student = students.find((s) => s.stuId === formData.student);

      return teachers.filter((teacher) =>
          teacher.subjects.some(
              (subject) =>
                  subject.name === formData.subject &&
                  subject.grade === student.grade
          )
      );
    }
  };

  const filteredStudents = () => {
    if (user.role === 3002) {
      const teacherSubjects = user.subjects.map((subject) => ({
        name: subject.name,
        grade: subject.grade,
      }));


      return students.filter((student) =>
          student.subjects.some((studentSubject) =>
              teacherSubjects.some(
                  (teacherSubject) =>
                      teacherSubject.name === studentSubject.name &&
                      teacherSubject.grade === studentSubject.grade
              )
          )
      );
    } else {
      return students.filter((student) => student.status === "active");
    }
  };




  return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <div className="lhs">
              <h2>Calendar</h2>
              <FaAngleRight className="greater-icon" />
              <h3>Add Class</h3>
            </div>
            <IoClose className="close-icon" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="event-form">
            <label className="drop-down-label">
              <CustomDropdown
                  options={[
                    { label: "Does Not Repeat", value: "Does Not Repeat" },
                    { label: "4 Weeks", value: "4 Weeks" },
                    { label: "12 Weeks", value: "12 Weeks" },
                  ]}
                  selectedValue={classRecursion}
                  onChange={(value) => setClassRecursion(value)}
                  placeholder="Does Not Repeat"
              />
            </label>

            <div className="title-container">
              <input
                  placeholder="Add title"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
              />
            </div>
            <label>
              <FaLink />
              <input
                  type="text"
                  name="link"
                  value={meetingLink || formData.link}
                  onChange={handleChange}
                  disabled={!!meetingLink}
                  required
                  placeholder="Add link"
              />
            </label>
            <div className="form-row">
              <label>
                <PiStudentBold/>
                <input
                    list="student-list"
                    name="student"
                    value={formData.student}
                    onChange={handleChange}
                    required
                    placeholder="Add Student"
                />
                <datalist id="student-list">
                  {filteredStudents().map((student) => (
                      <option key={student._id} value={student.stuId}/>
                  ))}
                </datalist>
              </label>
              <label>
                <FaBook/>
                <input
                    list="subject-list"
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    placeholder="Add subject"
                />
                <datalist id="subject-list">
                  {filteredSubjects().map((subject) => (
                      <option
                          key={subject._id}
                          value={user.role === 3002 ? subject.subjectName : subject.name}
                      />
                  ))}
                </datalist>

              </label>
            </div>
            <div className="form-row">
              <label>
                <MdDateRange/>
                <DatePicker
                    selected={formData.date}
                    onChange={handleDateChange}
                    required
                />
              </label>

              {user.role !== 3002 && (
                  <label>
                    <GiTeacher/>
                    <input
                        list="teacher-list"
                        name="teacher"
                        value={formData.teacher}
                        onChange={handleChange}
                        required
                        placeholder="Add Teacher"
                    />
                    <datalist id="teacher-list">
                      {filteredTeachers().map((teacher) => (
                          <option key={teacher._id} value={teacher.name} />
                      ))}
                    </datalist>
                  </label>
              )}
            </div>
            <div className="form-row">
              <label>
                <RiParentFill />
                <input
                    type="text"
                    name="studentConsultant"
                    value={formData.studentConsultant}
                    onChange={handleChange}
                    placeholder="Add Student Consultant"
                    list="studentConsultant-list"
                />
                <datalist id="studentConsultant-list">
                  {studentConsultants.map((studentConsultant) => (
                      <option key={studentConsultant._id} value={studentConsultant.name} />
                  ))}
                </datalist>
              </label>

              <label>
                Trial
                <input
                    type="checkbox"
                    name="isTrial"
                    checked={formData.isTrial}
                    onChange={handleChange}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                Start Time:
                <input
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    className="time-input"
                    onChange={(e) => handleTimeChange(e, "startTime")}
                    required
                />
              </label>
              <label>
                End Time: &nbsp;
                <input
                    type="time"
                    name="endTime"
                    className="time-input"
                    value={formData.endTime}
                    onChange={(e) => handleTimeChange(e, "endTime")}
                    required
                />
              </label>
            </div>
            <div className="form-buttons">
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default AddEventModal;
