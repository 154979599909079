import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa";
import { IoIosCopy } from "react-icons/io";
import Popup from "../../popup/Popup";
import { images } from "../../../utils/images";
import boy1 from "../../../assets/avatar/boy_1.jpg";
import boy2 from "../../../assets/avatar/boy_2.jpg";
import boy3 from "../../../assets/avatar/boy_3.jpg";
import girl1 from "../../../assets/avatar/girl_1.jpg";
import girl2 from "../../../assets/avatar/girl_2.jpg";
import girl3 from "../../../assets/avatar/girl_3.jpg";
import {reverseRoleMapping} from "../../../utils/helperFunctions/role";
import {getUserImage} from "../../../utils/helperFunctions/helperFunctions";

const studentImages = {
  boy_1: boy1,
  boy_2: boy2,
  boy_3:boy3,
  girl_1: girl1,
  girl_2: girl2,
  girl_3: girl3,
};

export const ViewEmployeeDetails = ({ employees, students, onClose }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popupTimeout, setPopupTimeout] = useState(null);


  const copyEmailToClipboard = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log("Email copied to clipboard");
        setShowPopup(true);

        if (popupTimeout) {
          clearTimeout(popupTimeout);
        }

        setPopupTimeout(setTimeout(() => {
          setShowPopup(false);
        }, 2000));
      })
      .catch((error) => {
        console.error("Failed to copy email: ", error);
      });
  };

  const assignedStudents = employees.assigned || [];


  const assignedStudentDetails = students?.filter((student) =>
    assignedStudents.includes(student.email)
  ).map((student) => {
    const studentProfileImage = studentImages[student.picture];
    return { ...student, studentProfileImage };
  });
  

  return (
    <div className="view-employee-tab">
      <div className="view-employee-content">
        <div className="header">
          <div className="lhs">
            <h2>Employee Information</h2>
            <FaAngleRight className="greater-icon" />
            <h3>
              {employees.name} {employees.lname}
            </h3>
          </div>
          <IoCloseSharp className="close-icon" onClick={onClose} />
        </div>
        <div className="employee-details">
          <h3 className="admin-tag">{reverseRoleMapping[employees.role]}</h3>
          <div className="image-container">
            {getUserImage(employees) && (
              <img src={getUserImage(employees)} alt="Profile" className="image" />
            )}
          </div>
          <div className="details">
            <div className="row-one">
              <div className="row-group">
                <p>Name </p>
                <p>:</p>
                <h3>
                  {employees.name} {employees.lname}
                </h3>
              </div>
              <div className="row-group">
                <p>Email </p>
                <p>:</p>
                <h3>
                  {employees.email}
                  <IoIosCopy
                      onClick={() => copyEmailToClipboard(employees.email)}
                  />
                </h3>
              </div>
            </div>

            <div className="row-two">
              <div className="col-one">
                <div className="row-group">
                  <p>ID</p>
                  <p>:</p>
                  <h3>{employees.empId}</h3>
                </div>
                <div className="row-group">
                  <p>Date of Birth </p>
                  <p>:</p>
                  <h3>{formatDate(employees.dateOfBirth)}</h3>
                </div>
              </div>
              <div className="col-one">
                <div className="row-group">
                  <p>Phone No </p>
                  <p>:</p>
                  <h3>{employees.phoneNumber}</h3>
                </div>
                <div className="row-group">
                  <p>Gender </p>
                  <p>:</p>
                  <h3>{employees.gender}</h3>
                </div>
              </div>
            </div>

            <div className="row-two">
              <div className="col-one">
                {employees.nic &&
                    <div className="row-group">
                      <p>Nic</p>
                      <p>:</p>
                      <h3>{employees.nic}</h3>
                    </div>}
              </div>
              {
                  employees.onboardedDate &&
                  <div className="col-one">
                    <div className="row-group">
                      <p>Onboarded Date </p>
                      <p>:</p>
                      <h3>{formatDate(employees.onboardedDate)}</h3>
                    </div>
                  </div>
              }
            </div>

            <div className="row-two">
              <div className="col-one">
                {
                    employees.address &&
                    <div className="row-group">
                      <p>Address</p>
                      <p>:</p>
                      <h3>{employees.address}</h3>
                    </div>
                }
              </div>
            </div>


          </div>
        </div>

        {employees.subjects.length !== 0 && (
          <div className="subjects">
            <h3>Subjects </h3>
            <div className="subject-list">
              {employees.subjects.map((subject) => (
                <div className="subject" key={subject.name}>
                  {subject.name}-{subject.grade}
                </div>
              ))}
            </div>
          </div>
        )}
        
        {assignedStudentDetails && assignedStudentDetails.length !== 0 && (
          <div className="assigned">
            <h3>Assigned Students</h3>
            <div className="assigned-list">
              {assignedStudentDetails.map((student) => (
                <div className="single-student" key={student.email}>
                  {student.studentProfileImage && (
                    <img src={student.studentProfileImage} alt="Profile" className="avatar" />
                  )}
                  <p>{student.name} {student.lname}</p>
                </div>
              ))}
            </div>
          </div>
        )}


      </div>

      {showPopup && (
          <Popup text={"Email copied to Clipboard!"} show={showPopup} onClose={() => setShowPopup(false)}/>
      )}
    </div>
  );
};

export default ViewEmployeeDetails;
