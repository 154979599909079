import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";

export const addCourse=async (setLoading,formData,setPopupText,setShowPopup)=>{
    setLoading(true);

    const cleanedFormData = {
        ...formData,
        grades: formData.grades.map((grade) => ({
            ...grade,
            fee: grade.fee.filter((f) => f.syllabus !== ""),
        })),
    };

    try {
        const response = await axiosInstance.post(
            endPoints.ADD_SUBJECT,
            cleanedFormData
        );
        console.log("Event added:", response.data);
        setPopupText("New Course added!");
        setShowPopup(true);
        setLoading(false);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    } catch (error) {
        console.error("Error adding event:", error);
        setPopupText("Error adding course.");
        setShowPopup(true);
        setLoading(false);
    }
}


export  const editCourse=async (setLoading,formData,setPopupText,setShowPopup)=>{
    setLoading(true);
    console.log("form data", formData);

    try {
        const cleanedFormData = {
            ...formData,
            grades: formData.grades.map((grade) => ({
                ...grade,
                fee: grade.fee.filter(
                    (f) => f.localFee !== "" || f.internationalFee !== ""
                ),
            })),
        };

        const response = await axiosInstance.put(
            `${endPoints.EDIT_SUBJECT}${formData._id}`,
            cleanedFormData
        );
        console.log("Course updated:", response.data);
        setPopupText("Course Updated Successfully!");
        setShowPopup(true);
        setLoading(false);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    } catch (error) {
        console.error("Error updating course:", error);
        setPopupText("Failed to update course. Please try again!");
        setShowPopup(true);
        setLoading(false);
    }
}