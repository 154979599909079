import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";
import {roleMapping} from "../helperFunctions/role";

export   const fetchNotification = async (user,setNotifications) => {
    if (user && user.email) {
        try {
            const response = await axiosInstance.get(
                `${endPoints.GET_NOTIFICATION}${user.email}`
            );
            setNotifications(response.data.notifications);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    }
};

export const editNotificationStatus=async (setShowMenu,user,toggleStatus,id,status)=>{
    try {
        setShowMenu(false)
        const newStatus = !status;
        await axiosInstance.put(
            `${endPoints.GET_NOTIFICATION}${user.email}/${id}`,
            { status: newStatus }
        );
        toggleStatus(id, newStatus);
    } catch (error) {
        console.error("Error updating notification status:", error);
    }
}

export const sendNotification = async (event, classId, topic, message,employees,setPopupText,setShowPopup) => {
    const relevantEmails = employees
        .filter(emp => emp.role === roleMapping["HR"] || emp.role === roleMapping["Admin"])
        .map(emp => emp.email);

    const notification = {
        email: [
            event.teacherEmail,
            event.studentEmail,
            event.studentConsultantEmail,
            ...relevantEmails
        ],
        classId: classId,
        date: event.date,
        topic: topic,
        data: message,
    };

    const notificationResponse = await axiosInstance.post(
        endPoints.ADD_NOTIFICATION,
        notification
    );

    if (notificationResponse.data.status === "Success") {
        console.log("Notification sent:", notificationResponse.data);
        setPopupText("Notification sent successfully!");
    } else {
        setPopupText("Failed to send notification. Please try again!");
    }
    setShowPopup(true);
};

export   const sendNotifications = async (event,eventDate,feeAmount,employees,setPopupText,setShowPopup) => {
    const eventWithFee = {
        ...event,
        price: feeAmount,
        date: eventDate,
    };

    const eventResponse = await axiosInstance.post(
        endPoints.ADD_CLASSES,
        eventWithFee
    );
    console.log("Event added:", eventResponse.data);

    if (eventResponse.data.status === "success") {
        const classId = eventResponse.data.class.classId;
        await sendNotification(
            event,
            classId,
            "New Class Scheduled",
            `Dear ${event.student} and ${event.teacher},
                    A new class has been scheduled on ${eventDate}. Please find the details below:

                    - Class ID: ${classId}
                    - Date: ${eventDate}
                    - Time: ${event.startTime}
                    - Topic: ${event.topic}

                    Looking forward to a productive session.

                    Best regards,
                    SMART EDU (PVT) LTD.`,
            employees,
            setPopupText,
            setShowPopup
        );

        const assignedResponse = await axiosInstance.put(
            `${endPoints.EMPLOYEE_ASSIGNED}${event.teacherEmail}`,
            { studentName: event.studentEmail }
        );
        if (assignedResponse.data.status === "Student assigned successfully") {
            console.log("Assigned Student Successfully:", assignedResponse.data);
        }
    }
};

