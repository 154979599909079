import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { images } from "../utils/images";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { axiosInstance } from "../utils/axiosInstance";
import { endPoints } from "../utils/endPoints";
import { useAuth } from "../auth/AuthContext";
import image1 from "../assets/login/enjoyingSchool.jpg";
import { Loader } from "./loader/Loader";
import { MutatingDots } from "react-loader-spinner";
import MobileView from "./mobileView/MobileView";

export function Login() {
  const [email, setEmail] = useState("");
  const [passwordHash, setPasswordHash] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();
  let navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required!";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!passwordHash) {
      newErrors.passwordHash = "Password is required!";
    } else if (passwordHash.length === 0) {
      newErrors.passwordHash = "Password must be at least 1 character long";
    }

    setErrors(newErrors);
    setShowErrorPopup(Object.keys(newErrors).length > 0);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (showErrorPopup) {
      const timer = setTimeout(() => {
        setShowErrorPopup(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showErrorPopup]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(endPoints.LOGIN, {
          email,
          passwordHash,
        });
        if (response.data.status === "ok") {
          const token = response.data.token;
          localStorage.setItem("token", token);
          login(token);
          navigate("/adminPanel");
        } else {
          setErrors({ form: "Email or password is incorrect" });
          setShowErrorPopup(true);
        }
      } catch (error) {
        const errorMsg = error.response
          ? error.response.data.message
          : "An error occurred. Please try again.";
        setErrors({ form: errorMsg });
        setShowErrorPopup(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
      <>
        <div className="login-component">
          {loading && (
              <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#8a70d6"
                  secondaryColor="#E9E3FF"
                  radius="20"
                  ariaLabel="mutating-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass="custom-loader-wrapper"
              />
          )}
          <div className="image-container">
            <img src={image1} alt="Enjoying School" className="image"/>
          </div>
          <div className="login-first-column">
            <div className="login-form">
              <div className="header">
                <img src={images.Logo} alt="Logo"/>
                <h2>Welcome Back!</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="input-container">
                  <input
                      type="email"
                      name="email"
                      placeholder="Please Enter Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <input
                      type={passwordVisible ? "text" : "password"}
                      name="passwordHash"
                      placeholder="Please Enter Your Password"
                      value={passwordHash}
                      onChange={(e) => setPasswordHash(e.target.value)}
                  />
                  <span
                      className="password-toggle-icon"
                      onClick={togglePasswordVisibility}
                  >
                {passwordVisible ? <FaEye/> : <FaEyeSlash/>}
              </span>
                </div>
                <div className="forget-password">
                <a href="/forget-password">Forget Password</a>                </div>
                <button type="submit" className="submit-btn" disabled={loading}>
                  Log in
                </button>
              </form>
            </div>
          </div>

          {showErrorPopup && (
              <div className="error-popup">
                {Object.values(errors).map((error, index) => (
                    <p key={index}>{error}</p>
                ))}
              </div>
          )}
        </div>
        <MobileView/>
      </>
  );
}
