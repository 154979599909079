import React, { useState } from 'react';
import '../tagsInput/TagsInput.css';

const TagsInput = ({ tags, setTags, subjects = [], editable, grades = [] }) => {
    const [input, setInput] = useState({ name: '', grade: '' });
    const [subjectSuggestions, setSubjectSuggestions] = useState([]);
    const [gradeSuggestions, setGradeSuggestions] = useState([]);
    const [filteredGrades, setFilteredGrades] = useState([]);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setInput({ ...input, [name]: value });
    //
    //     if (name === 'name' && value) {
    //         const filteredSubjectSuggestions = subjects.filter(
    //             (subject) =>
    //                 subject.subjectName &&
    //                 subject.subjectName.toLowerCase().includes(value.toLowerCase())
    //                 // &&
    //                 // !tags.some(tag => tag.name === subject.subjectName)
    //         );
    //         setSubjectSuggestions(filteredSubjectSuggestions);
    //
    //         const selectedSubject = subjects.find(
    //             (subject) =>
    //                 subject.subjectName.toLowerCase() === value.toLowerCase()
    //         );
    //         if (selectedSubject) {
    //             setFilteredGrades(selectedSubject.grades.map(grade => grade.level));
    //         } else {
    //             setFilteredGrades([]);
    //         }
    //     } else if (name === 'grade' && value) {
    //         const filteredGradeSuggestions = filteredGrades.filter(
    //             (gradeLevel) =>
    //                 gradeLevel.toLowerCase().includes(value.toLowerCase()) &&
    //                 !tags.some(tag => tag.grade.toLowerCase() === gradeLevel.toLowerCase())
    //         );
    //         setGradeSuggestions(filteredGradeSuggestions);
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput({ ...input, [name]: value });

        if (name === 'name' && value) {
            // Filter subjects based on the input value
            const filteredSubjectSuggestions = subjects.filter(
                (subject) =>
                    subject.subjectName &&
                    subject.subjectName.toLowerCase().includes(value.toLowerCase())
            );
            setSubjectSuggestions(filteredSubjectSuggestions);

            // Find the selected subject
            const selectedSubject = subjects.find(
                (subject) =>
                    subject.subjectName.toLowerCase() === value.toLowerCase()
            );

            if (selectedSubject) {
                // Filter grades for the selected subject that don't already have a tag
                const availableGrades = selectedSubject.grades.filter(grade =>
                    !tags.some(tag =>
                        tag.name.toLowerCase() === selectedSubject.subjectName.toLowerCase() &&
                        tag.grade.toLowerCase() === grade.level.toLowerCase()
                    )
                ).map(grade => grade.level);
                setFilteredGrades(availableGrades);
            } else {
                setFilteredGrades([]);
            }
        } else if (name === 'grade' && value) {
            // Filter grade suggestions based on the input value and ensure both subject and grade are not already tagged
            const filteredGradeSuggestions = filteredGrades.filter(
                (gradeLevel) =>
                    gradeLevel.toLowerCase().includes(value.toLowerCase()) &&
                    !tags.some(tag =>
                        tag.grade.toLowerCase() === gradeLevel.toLowerCase() &&
                        tag.name.toLowerCase() === input.name.toLowerCase() // Check for the selected subject name
                    )
            );
            setGradeSuggestions(filteredGradeSuggestions);
        }
    };


    const handleKeyDown = (e) => {
        if (editable && e.key === 'Enter' && input.name && input.grade) {
            e.preventDefault();
            const selectedSubject = subjects.find((subject) =>
                subject.subjectName && subject.subjectName.toLowerCase() === input.name.toLowerCase()
            );
            if (selectedSubject) {
                const selectedGrade = selectedSubject.grades.find((grade) =>
                    grade.level.toLowerCase() === input.grade.toLowerCase()
                );
                if (selectedGrade) {
                    const existingTagIndex = tags.findIndex(tag => tag.name === selectedSubject.subjectName && tag.grade === selectedGrade.level);
                    if (existingTagIndex === -1) {
                        setTags([...tags, { name: selectedSubject.subjectName, grade: selectedGrade.level }]);
                    } else {
                        console.log('Tag already exists with the same name and grade');
                    }
                }
            }
            setInput({ name: '', grade: '' });
            setSubjectSuggestions([]);
            setGradeSuggestions([]);
        }
    };

    const handleSubjectSuggestionClick = (subject) => {
        if (editable) {
            setInput({ ...input, name: subject.subjectName });
            setSubjectSuggestions([]);
            setFilteredGrades(subject.grades.map(grade => grade.level));
        }
    };

    const handleGradeSuggestionClick = (gradeLevel) => {
        if (editable) {
            setInput({ ...input, grade: gradeLevel });
            setGradeSuggestions([]);
        }
    };

    const handleRemoveTag = (index) => {
        if (editable) {
            setTags(tags.filter((tag, i) => i !== index));
        }
    };

    return (
        <div className="tags-input-container">
            {tags.map((tag, index) => (
                <div className="tag" key={index}>
                    {tag.name} (Grade: {tag.grade})
                    {editable && (
                        <span className="tag-close-icon" onClick={() => handleRemoveTag(index)}>
                            &times;
                        </span>
                    )}
                </div>
            ))}
            {editable && (
                <div className="input-group">
                    <input
                        type="text"
                        name="name"
                        value={input.name}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Add a subject"
                    />
                    <input
                        type="text"
                        name="grade"
                        value={input.grade}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Add a grade"
                    />
                </div>
            )}
            {subjectSuggestions.length > 0 && editable && (
                <ul className="suggestions-list">
                    {subjectSuggestions.map((subject, index) => (
                        <li key={index} onClick={() => handleSubjectSuggestionClick(subject)}>
                            {subject.subjectName}
                        </li>
                    ))}
                </ul>
            )}
            {gradeSuggestions.length > 0 && editable && (
                <ul className="suggestions-list">
                    {gradeSuggestions.map((gradeLevel, index) => (
                        <li key={index} onClick={() => handleGradeSuggestionClick(gradeLevel)}>
                            {gradeLevel}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TagsInput;
