import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";

export const fetchUserData = async (setUser,setLoading) => {
    try {
        const token = localStorage.getItem("token");

        const response = await axiosInstance.post(
            endPoints.USER_VERIFICATION,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.data.status) {
            const email = response.data.user;

            const userInfoResponse = await axiosInstance.get(
                `${endPoints.GET_USER_INFO}?email=${email}`
            );
            console.log("User Info:", userInfoResponse.data);
            setUser(userInfoResponse.data.employee);
        } else {
            localStorage.removeItem("token");
            console.log("User verification failed:", response.data.message);
        }
    } catch (error) {
        console.error("Error verifying user:", error);
    } finally {
        setLoading(false);
    }
};