import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineMail } from "react-icons/hi";
import { PiDotsThreeFill } from "react-icons/pi";
import { RiKey2Fill } from "react-icons/ri";
import { FaLock } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import Popup from "../popup/Popup";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";
import emailjs from '@emailjs/browser';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ForgetPassword() {
  const [activeTab, setActiveTab] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState(["", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [generatedCode, setGeneratedCode] = useState("");
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [emailSent, setEmailSent] = useState(false);

  const handleNext = () => {
    if (activeTab < 4) {
      setActiveTab(activeTab + 1);
    } else {
      navigate('/');
    }
  };

  const handleEmailSent = async (e) => {
    e.preventDefault();
    try {
      if (validateEmail(email)) {
        const userResponse = await axiosInstance.get(`${endPoints.GET_USER_INFO}?email=${email}`);

        if (userResponse.status === 200 && userResponse.data.employee) {
          const code = Math.floor(1000 + Math.random() * 9000);
          setGeneratedCode(code.toString());


          await emailjs
            .send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID,
              process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
              {
                to_name: userResponse.data.employee.name,
                send_to: userResponse.data.employee.email,
                code: code
              },
              process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then(
              () => {
                setPopupText("Email Sent Successfully!");
                setShowPopup(true);
                setEmailSent(true); 
                setTimeout(() => {
                  setShowPopup(false);
                  handleNext();
                  console.log("SUCCESS!");
                  
                }, 3000);
              },
              (error) => {
                setPopupText("Email Sent Failed!");
                setShowPopup(true);
                setTimeout(() => {
                  setShowPopup(false);
                }, 3000);
                console.log('FAILED...', error);
              }
            );
        } else if (userResponse.status === 404) {
          setPopupText("Email not found!");
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
          }, 3000);
        } else {
          setPopupText("An error occurred. Please try again.");
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
          }, 3000);
        }
      } else {
        setPopupText("Please enter a valid email address.");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        if (error.response.status === 404) {
          setPopupText("Email not found!");
        } else {
          setPopupText("An error occurred. Please try again.");
        }
      } else if (error.request) {
        setPopupText("Network error. Please check your connection.");
      } else {
        setPopupText("An error occurred. Please try again.");
      }
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }
  };

  const handleResendEmail = (e) => {
    e.preventDefault();
    setPopupText("Email resent Successfully!");
    setShowPopup(true);
  };

  const handleCodeChange = (e, index) => {
    const { value } = e.target;

    const newCode = [...code];

    if (e.key === "Backspace") {
      newCode[index] = "";
      if (index > 0) {
        const prevInput = document.getElementById(`code-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    } else if (/^[0-9]$/.test(value)) {
      newCode[index] = value;

      if (index < code.length - 1) {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }

    setCode(newCode);
  };




  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (code.join('') === generatedCode) {
      handleNext();
    } else {
      setPopupText("Incorrect code. Please try again.");
      setShowPopup(true);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePasswordReset =async (e) => {
    e.preventDefault();
    if (newPassword !== "" && newPassword === confirmPassword) {

      try {
        const response = await axiosInstance.put(endPoints.CHANGE_PASSWORD_DIRECT, {
          email: email,
          newPassword: newPassword,
        });
        console.log("Password changed:", response.data);

        if (response.data.status==="Password changed successfully"){
          setPopupText("Password changed successfully!");
          setShowPopup(true);
          handleNext();
        }
        else {
          setPopupText("Something Went wrong!");
          setShowPopup(true);
        }

      } catch (error) {
        console.error("Error changing password:", error);
        setPopupText("Failed to change password. Please try again!");
        setShowPopup(true);
      }

    } else {
      setPopupText("Passwords do not match or are empty.");
      setShowPopup(true);
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
      <div className="container-forget">
        {activeTab === 1 && (
            <div className="lock-icon">
              <FaLock className="icon" />
            </div>
        )}
        {activeTab === 2 && (
            <div className="lock-icon">
              <PiDotsThreeFill className="icon" />
            </div>
        )}
        {activeTab === 3 && (
            <div className="lock-icon">
              <RiKey2Fill className="icon" />
            </div>
        )}
        {activeTab === 4 && (
            <div className="lock-icon">
              <MdDone className="icon" />
            </div>
        )}

      {activeTab === 1 && (
        <form onSubmit={handleEmailSent}>
        <h2 className="title">Reset your password</h2>
        <p className="description">Forgot your password? Please enter your email and we'll send you a 4-digit code.</p>
        {emailSent && (
            <p className="email-notification">We have sent an email to {email}</p>
         )} 
        <input
          type="email"
          placeholder="Enter your email"
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit" className="button">Get 4-digit code</button>
      </form>
      )}

      {activeTab === 2 && (
        <form onSubmit={handleCodeSubmit}>
          <h2 className="title">Enter confirmation code</h2>
          <div className="code-inputs">
            {code.map((num, index) => (
              <input
                key={index}
                id={`code-input-${index}`}
                type="text"
                maxLength="1"
                className="code-input"
                value={num}
                onChange={(e) => handleCodeChange(e, index)}
                onKeyDown={(e) => handleCodeChange(e, index)}
                style={{ MozAppearance: "textfield", appearance: "textfield" }}
              />
            ))}
          </div>

          <button type="submit" className="button">Continue</button>
          <p className="resend">
            Didn’t receive the email? <span onClick={handleResendEmail}>Click to resend</span>
          </p>
        </form>
      )}

      {activeTab === 3 && (
      <form onSubmit={handlePasswordReset}>
      <h2 className="title">Create a new password</h2>
      <p className="description">Please choose a password that hasn’t been used before.</p>
      
      <div className="input-container">
        <input
          type={passwordVisibility.newPassword ? 'text' : 'password'}
          placeholder="Set new password"
          className="input"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <span className="toggle-icon" onClick={() => togglePasswordVisibility('newPassword')}>
          {passwordVisibility.newPassword ? <FaEye /> : <FaEyeSlash />}
        </span>
      </div>
      
      <div className="input-container">
        <input
          type={passwordVisibility.confirmPassword ? 'text' : 'password'}
          placeholder="Confirm new password"
          className="input"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <span className="toggle-icon" onClick={() => togglePasswordVisibility('confirmPassword')}>
          {passwordVisibility.confirmPassword ? <FaEye /> : < FaEyeSlash/>}
        </span>
      </div>
      
      <button type="submit" className="button">Reset password</button>
    </form>
      )}

      {activeTab === 4 && (
        <>
          <h2 className="title">Password Reset!</h2>
          <p className="description">Your Password has been Successfully reset! Click below to login.</p>
          <button className='continue-btn' onClick={handleNext}>Continue</button>
        </>
      )}

        <div className="pagination">
          <span className={activeTab === 1 ? 'dot active' : 'dot'}></span>
          <span className={activeTab === 2 ? 'dot active' : 'dot'}></span>
          <span className={activeTab === 3 ? 'dot active' : 'dot'}></span>
          <span className={activeTab === 4 ? 'dot active' : 'dot'}></span>
        </div>

        <Popup
            show={showPopup}
            text={popupText}
            onClose={() => setShowPopup(false)}
        />
      </div>
  );
}

export default ForgetPassword;
