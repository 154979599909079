import React, { useState } from "react";
import { FaInbox } from "react-icons/fa6";
import { TbBrandZoom } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa";
import { LuPencil } from "react-icons/lu";
import { RiCloseLargeFill } from "react-icons/ri";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";

const CalendarEventModal = ({
                              isOpen,
                              onClose,
                              event,
                              handleOpenEditModal,
                              user,
                              students,
                            }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "#77DD77";
      case "cancelled":
        return "#FF6961";
      case "pending":
        return "#E8DECB";
      case "incomplete":
        return "#FF7518";
      default:
        return "#8a70d6";
    }
  };

  const [statusColor, setStatusColor] = useState(getStatusColor(event.status));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [cancelledRemark, setCancelledRemark] = useState("");

  const getStudentNameById = (stuId) => {
    const student = students.find((student) => student.stuId === stuId);
    return student ? student.name : "Unknown Student";
  };

  const handleColorClick = () => {
    if (
        user.role !== 3004
    ) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const handleRemarkClose = () => {
    setIsFormOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.put(
          `${endPoints.EDIT_CANCELLED_REMARK}${event.classId}`,
          { cancelledRemark }
      );
      console.log("Remark added:", response.data);

      if (response.data.status==="success") {
        await updateClassStatus(statusColor === "#FF6961" ? "cancelled" : "incomplete");

      }
    } catch (error) {
      console.error("Error updating remark:", error);
    }
  };

  const updateClassStatus = async (status) => {
    try {
      const response = await axiosInstance.put(
          `${endPoints.EDIT_CLAS_STATUS}${event.classId}`,
          { status }
      );
      console.log("Status updated:", response.data);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleColorSelect = (color) => {
    setStatusColor(color);
    setDropdownOpen(false);

    const status =
        color === "#77DD77"
            ? "approved"
            : color === "#FF6961"
                ? "cancelled"
                : color === "#dcb06e"
                    ? "pending"
                    : color === "#FF7518"
                        ? "incomplete"
                        : "";

    if (status === "cancelled" || status === "incomplete") {
      setIsFormOpen(true);
    } else if (status) {
      updateClassStatus(status);
    }
  };

  // Extract and format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString(); // Returns something like "Tue Jun 25 2024"
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes}${ampm}`;
  };

  const formatTimeRange = (start, end) => {
    return `${formatTime(start)} – ${formatTime(end)}`;
  };

  if (!isOpen) return null;

  return (
      <div className="custom-modal-overlay">
        <div className="custom-modal">
          <div className="custom-modal-header">
            <div className="modal-status-container">
              {
                  !isFormOpen &&
                  <div className="big-status-circle">
                    <div
                        className="status-circle"
                        onClick={handleColorClick}
                        style={{backgroundColor: statusColor}}
                    ></div>
                  </div>
              }
              {dropdownOpen && (
                  <div className="dropdown-menu">
                    {user.role !== 3002 && (
                        <>
                          <div onClick={() => handleColorSelect("#77DD77")}>
                            <div
                                className="small-circle"
                                style={{ backgroundColor: "#77DD77" }}
                            ></div>
                            <p>Approved</p>
                          </div>
                          <div onClick={() => handleColorSelect("#dcb06e")}>
                            <div
                                className="small-circle"
                                style={{ backgroundColor: "#dcb06e" }}
                            ></div>
                            <p>Pending</p>
                          </div>
                        </>
                    )}
                    <div onClick={() => handleColorSelect("#FF6961")}>
                      <div
                          className="small-circle"
                          style={{ backgroundColor: "#FF6961" }}
                      ></div>
                      <p>Cancelled</p>
                    </div>
                    <div onClick={() => handleColorSelect("#FF7518")}>
                      <div
                          className="small-circle"
                          style={{ backgroundColor: "#FF7518" }}
                      ></div>
                      <p>Incomplete</p>
                    </div>
                  </div>
              )}
            </div>
            {(user.role !== 3004) && !isFormOpen && <LuPencil onClick={handleOpenEditModal} />}
            <RiCloseLargeFill className="close-icon" onClick={onClose} />
          </div>
          <div className="custom-modal-body">
            {!isFormOpen ? (
                <div className="body-header">
                  <div className="title-container">
                    <div  style={{width:"10%"}}>
                      <FaInbox/>
                    </div>
                    <div style={{width:"100%"}}>
                      <h2>
                        {event.title}
                      </h2>
                      <p>
                        {formatDate(event.start)} .{" "}
                        {formatTimeRange(event.start, event.end)}
                      </p>
                    </div>
                  </div>
                  <a href={event.link}>
                    <TbBrandZoom/> &nbsp; &nbsp; Join Meeting
                  </a>
                  <div className="guest-container">
                    <FaRegUser/>
                    <div className="guest-list">
                      <h6> &nbsp; &nbsp; &nbsp;{event.teacher} </h6>
                      <h6> &nbsp; &nbsp; &nbsp;{getStudentNameById(event.student)} </h6>
                    </div>
                  </div>
                </div>
            ) : (
                <div className="remark-form-container">
                  <form onSubmit={handleFormSubmit}>
                    <label>
                      Remark: &nbsp;
                      <textarea
                          cols={100}
                          type="text"
                          name="remark"
                          placeholder="Add remark"
                          value={cancelledRemark}
                          onChange={(e) => setCancelledRemark(e.target.value)}
                          required={true}
                      />
                    </label>
                    <div className="form-buttons">
                      <button type="button" onClick={handleRemarkClose}>
                        Cancel
                      </button>
                      <button type="submit">Add</button>
                    </div>
                  </form>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default CalendarEventModal;
