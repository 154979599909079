import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";

export   const fetchGrades = async (setGrades) => {
    try {
        const response = await axiosInstance.get(endPoints.GET_GRADES);
        setGrades(response.data.grades);
    } catch (error) {
        console.error("Error fetching grades:", error);
    }
};