export  const roleMapping = {
    Developer:1000,
    Admin: 3000,
    HR: 3001,
    Teacher: 3002,
    StudentConsultant: 3003,
    Student: 3004,
};

export const  reverseRoleMapping = {
    1000:"Developer",
    3000: "Admin",
    3001: "HR",
    3002: "Teacher",
    3003: "StudentConsultant",
    3004: "Student",
};