import React, {useState} from "react";
import {CiMenuKebab} from "react-icons/ci";
import {editNotificationStatus} from "../../utils/apis/Notification";

const NotificationItemCard = ({
  index,
  image1,
  name,
  heading,
  note,
  date,
  id,
  user,
  status,
  toggleStatus,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleStatus =  () => {
     editNotificationStatus(setShowMenu,user,toggleStatus,id,status)
  };

  return (
    <div key={index} className="notification-item">
      <div className="image-container">
        <img src={image1} alt="notification" className="image" />
      </div>
      <div className="notification-content">
        <div className="col-one">
          <h2>{name}</h2>
          {/*<h3 className="heading">{heading}</h3>*/}
          <h3 className="note">{note}</h3>
        </div>
        <div className="col-two">
          <div className="btns">
            <CiMenuKebab
              className="delete"
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
          <div className="date-time">
            <h3 className="date">{date}</h3>
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="notification-card-menu">
          <p onClick={handleToggleStatus}>
            {status ? "Mark as read" : "Mark as unread"}
          </p>
        </div>
      )}
    </div>
  );
};

export default NotificationItemCard;
