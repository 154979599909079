import React, { useState, useEffect } from 'react';
import image1 from '../../assets/bookImage.png';
import studentWorking from '../../assets/mobileView/studentWorking.jpg';
import nophone from '../../assets/mobileView/noPhone.jpg';
import logo from '../../assets/logo/Tuto-1.png'
import oops from '../../assets/mobileView/oops.png'

const MobileView = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    image1,
    studentWorking,
    oops,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="mobile-container">
      <img src={logo} className='logo'/>
      <img src={images[currentIndex]} alt="slide" className="mobile-image" />
      <div className="mobile-content">
        <h1 className="mobile-title">PC Only Notice</h1>
        <p className="mobile-text">
        This application is only available on PC. Please switch to a PC to access the app.
        </p>
        <div className="mobile-indicators">
          {images.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileView;
