import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";
import {addImagesToUser} from "../helperFunctions/helperFunctions";

export   const fetchStudents = async (setStudents) => {
    try {
        const response = await axiosInstance.get(endPoints.GET_STUDENTS);
        setStudents(response.data.students);
    } catch (error) {
        console.error("Error fetching classes:", error);
    }
};

export const addStudent=async (setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)=>{
    setIsLoading(true);
    console.log("Form Data:", formData);

    const updatedFormData=addImagesToUser(formData)

    try {
        const response = await axiosInstance.post(
            endPoints.ADD_STUDENT,
            updatedFormData
        );

        if (response.data.status === "success") {
            setPopupText("Student Added Successfully!");
            setShowPopup(true);
            console.log("Student Added:", response.data);

            for (const subject of formData.subjects) {
                const enrollResponse = await axiosInstance.post(
                    endPoints.ADD_ENROLLMENT,
                    {
                        subjectName: subject.name,
                        gradeLevel: subject.grade,
                    }
                );
                console.log("Enrollment Updated:", enrollResponse.data);
            }
            setIsLoading(false);
            handleSubmitCancel();
        }
    } catch (error) {
        setPopupText("Failed to add student. Please try again!");
        setShowPopup(true);
        console.error("Error adding student:", error);
        setIsLoading(false);
    }
}
export const updateStudentStatus = async (student, newStatus,setIsLoading) => {
    try {
        setIsLoading(true);
        const response = await axiosInstance.put(
            `${endPoints.UPDATE_STATUS_STUDENT}/${student.email}`,
            {
                status: newStatus,
            }
        );

        if (response.data.status === "success") {
            console.log("Status updated:", response.data);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    } catch (error) {
        console.error("Error updating status:", error);
    } finally {
        setIsLoading(false);
    }
};

export const editStudent=async (setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)=>{
    setIsLoading(true);
    console.log("Form Data:", formData);
    try {
        const updateData = {
            ...formData,
        };
        const response = await axiosInstance.put(
            `${endPoints.EDIT_STUDENT_BY_ADMIN}${formData.email}`,
            updateData
        );

        if (response.data.status==="success"){
            console.log("Student updated:", response.data);
            setPopupText("Student Details Updated Successfully!");
            setShowPopup(true);

            for (const subject of formData.subjects) {
                const enrollResponse = await axiosInstance.post(
                    endPoints.ADD_ENROLLMENT,
                    {
                        subjectName: subject.name,
                        gradeLevel: subject.grade,
                    }
                );
                console.log("Enrollment Updated:", enrollResponse.data);
            }
            setIsLoading(false);
            handleSubmitCancel()
        }
    } catch (error) {
        console.error("Error updating Student:", error);
        setIsLoading(false);
        setPopupText("Failed to update Student details. Please try again!");
        setShowPopup(true);
    }
}

