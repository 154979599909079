import React, { useState } from 'react';
import './CustomDropdown.css';
import { MdOutlineArrowDropDown } from "react-icons/md";


const CustomDropdown = ({ options, selectedValue, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState(selectedValue);

    const handleSelect = (value) => {
        setCurrentValue(value);
        onChange(value);
        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown">
            <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                <p>{currentValue || placeholder}</p>
                <MdOutlineArrowDropDown/>

            </div>
            {isOpen && (
                <ul className="dropdown-list">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="dropdown-item"
                            onClick={() => handleSelect(option.value)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
