import React, {useEffect, useState} from "react";
import {FaAngleRight} from "react-icons/fa";
import {IoCloseSharp} from "react-icons/io5";
import SyllabusDropdown from "./subComponents/SyllabusDropdown";
import Popup from "../../popup/Popup";
import {AiTwotoneDelete} from "react-icons/ai";
import {editCourse} from "../../../utils/apis/Cource";
import CourseDetailsForm from "./subComponents/CourseDetailsForm";
import GradeForm from "./subComponents/GradeForm";
import {
  handleCheckboxChange,
  validateForm,
  handleChange,
  deleteGrade,
  handleClick
} from "../../../utils/courseForm/courseFormUtils";

const syllabusList = ["Cambridge", "Edexcel", "AQA"];

function EditCourseTab({ onClose, grades, subjects, setLoading }) {
  const [activeButton, setActiveButton] = useState();
  const [formData, setFormData] = useState({
    _id: subjects?._id || "",
    subjectName: subjects?.subjectName || "",
    code: subjects?.code || "",
    grades: subjects?.grades?.map((grade) => ({
      level: grade.level,
      fee: syllabusList.map((syllabus) => {
        const existingFee = grade.fee.find((f) => f.syllabus === syllabus);
        return (
          existingFee || {
            syllabus,
            visible: false,
            localFee: "",
            internationalFee: "",
            isNew: true,
          }
        );
      }),
    })),
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  useEffect(() => {
    const selectedGrades = formData.grades.map((grade) => grade.level);
    setDisabledGrades(selectedGrades);
  }, [formData]);

  const [disabledGrades, setDisabledGrades] = useState([]);

  const addNewGrade = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grades: [
        ...prevFormData.grades,
        {
          level: "",
          fee: syllabusList.map((syllabus) => ({
            syllabus,
            visible: false,
            localFee: "",
            internationalFee: "",
            isNew: true,
          })),
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(formData, setPopupText, setShowPopup)) {
      return;
    }
    editCourse(setLoading, formData, setPopupText, setShowPopup);
  };

  return (
    <div className="edit-course-tab">
      <div className="edit-content">
        <div className="header">
          <div className="lhs">
            <h2>All Courses</h2>
            <FaAngleRight className="greater-icon" />
            <h3>Edit Course</h3>
          </div>
          <IoCloseSharp className="close-icon" onClick={onClose} />
        </div>
        <div className="content">
          <form onSubmit={handleSubmit}>
            <CourseDetailsForm formData={formData} setFormData={setFormData} />
            <GradeForm
              formData={formData}
              disabledGrades={disabledGrades}
              syllabusList={syllabusList}
              grades={grades}
              handleClick={(label, index) =>
                handleClick(label, index, formData, setFormData, setActiveButton)
              }
              handleCheckboxChange={(gradeIndex, syllabusIndex) =>
                handleCheckboxChange(
                  formData,
                  setFormData,
                  gradeIndex,
                  syllabusIndex
                )
              }
              handleChange={(e, gradeIndex, syllabusIndex) =>
                handleChange(e, formData, setFormData, gradeIndex, syllabusIndex)
              }
              deleteGrade={(index) =>
                deleteGrade(index, formData, setFormData)
              }
              addNewGrade={addNewGrade}
            />
            <div className="button-group">
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
      <Popup
        show={showPopup}
        text={popupText}
        onClose={() => setShowPopup(false)}
      />
    </div>
  );
}

export default EditCourseTab;
