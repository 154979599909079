import image1 from "../../../assets/bookImage.png";
import React from "react";

export const PopularCourseCard = ({ image, subject, code }) => {
  return (
    <div className="course-component">
      <div className="image-contanier">
        <img src={image1} alt="image" className="image" />
      </div>
      <div className="text-contanier">
        <h2>{subject}</h2>
        <h3>{code}</h3>
      </div>
      {/*<div className="learn-more">Learn More</div>*/}
    </div>
  );
};
