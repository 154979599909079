import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";
import {sendNotification} from "./Notification";

export  const fetchClasses = async (setClasses) => {
    try {
        const response = await axiosInstance.get(endPoints.GET_CLASSES);
        setClasses(response.data.classes);
    } catch (error) {
        console.error("Error fetching classes:", error);
    }
};

export const editClass=async (event,employees,setPopupText,setShowPopup,setShowEditModal,)=>{
    try {
        const eventResponse = await axiosInstance.put(
            `${endPoints.EDIT_CLASS}${event.classId}`,
            event
        );
        console.log("Event Edited:", eventResponse.data);
        setPopupText("Event updated Successfully.");
        setShowPopup(true);

        if (eventResponse.data.status === "success") {
            await sendNotification(
                event,
                event.classId,
                "Class Updated",
                `Dear ${event.teacher} and ${event.student},
            The class on ${event.date} has been updated. Please find the updated details below:

            - Class ID: ${event.classId}
            - Date: ${event.date}
            - Time: ${event.startTime}
            - Topic: ${event.topic}

            Please review the changes.

            Best regards,
            SMART EDU (PVT) LTD.`,
                employees,
                setPopupText,
                setShowPopup
            );
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }

        setShowEditModal(false);
    } catch (error) {
        console.error("Error editing event:", error);
        setPopupText("Failed to update event. Please try again!");
        setShowPopup(true);
    }

}