import React, { useState } from "react";
import image1 from "../../assets/bookImage.png";
import { FiEdit3 } from "react-icons/fi";
import { images } from "../../utils/images";

function CourseCard({ course, onMoreDetailsClick, onEditClick, user }) {
  const [isActive, setIsActive] = useState(course.status === "active");

  const handleStatusToggle = () => {
    setIsActive(!isActive);
  };

  const patterns = images.patterns;

  const patternIndex =
    patterns.length > 0 ? Math.floor(Math.random() * patterns.length) : null;

  return (
    <div className="course-card">
      <div className="image-content">
        {patternIndex !== null && (
          <img
            src={patterns[patternIndex]}
            className="course-image"
            alt="Course"
          />
        )}
        {(user.role === 1000 || user.role === 3000 || user.role === 3001) && (
          <FiEdit3 className="edit-icon" onClick={() => onEditClick(course)} />
        )}
      </div>
      <div className="course-details">
        <h3 className="code">Code: {course.code}</h3>
        <h3 className="name">
          <span>{course.subjectName}</span>
        </h3>
        {/*<div className="status">*/}
        {/*  <label className="switch">*/}
        {/*    {(user.role === 1000 ||  user.role === 3000 || user.role === 3001) && (*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        checked={isActive}*/}
        {/*        onChange={handleStatusToggle}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    <span className="slider round"></span>*/}
        {/*  </label>*/}
        {/*</div>*/}
        <div className="other-details">
          <div
              className="more-info-btn"
              onClick={() => onMoreDetailsClick(course)}
          >
            More Details
          </div>
          <div className="avatars">
            {course.enrollCount > 0 && (
                <>
                  {Array.from({length: Math.min(course.enrollCount, 3)}).map((_, index) => (
                      <img key={index} src={image1} alt="Avatar" className="avatar"/>
                  ))}
                  {course.enrollCount > 3 && (
                      <span>+{course.enrollCount - 3}</span>
                  )}
                </>
            )}
          </div>

        </div>
      </div>
    </div>
  );
}

export default CourseCard;
