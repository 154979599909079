import React, { useState, useEffect } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import "../report/report.css";

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const Report = ({ classes }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const generateChartData = () => {
            const earningsPerMonth = Array(12).fill(null).map(() => ({}));

            classes.forEach((dataItem) => {
                const date = new Date(dataItem.date);
                const month = date.getMonth();
                const subject = dataItem.subject;
                const earnings = dataItem.price || 0;

                if (!earningsPerMonth[month][subject]) {
                    earningsPerMonth[month][subject] = 0;
                }
                earningsPerMonth[month][subject] += earnings;
            });

            const data = earningsPerMonth.map((monthData, index) => {
                const monthName = monthNames[index];
                return { month: monthName, ...monthData };
            });

            return data;
        };

        // Generate chart data from classes
        const dataForChart = generateChartData();
        setChartData(dataForChart);
    }, [classes]); // Trigger useEffect whenever classes prop changes

    const generateLines = () => {
        const subjects = new Set(classes.map(dataItem => dataItem.subject));
        return Array.from(subjects).map((subject, index) => (
            <Line
                key={index}
                type="monotone"
                dataKey={subject}
                stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                strokeWidth={2}
            />
        ));
    };

    return (
        <div className="report-container">
            <LineChart
                width={1000}
                height={600}
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                {generateLines()}
            </LineChart>
        </div>
    );
};

export default Report;
