import React, {useEffect, useState} from "react";
import {FaAngleRight} from "react-icons/fa";
import {IoCloseSharp} from "react-icons/io5";
import SyllabusDropdown from "./subComponents/SyllabusDropdown";
import Popup from "../../popup/Popup";
import {AiTwotoneDelete} from "react-icons/ai";
import {addCourse} from "../../../utils/apis/Cource";
import CourseDetailsForm from "./subComponents/CourseDetailsForm";
import GradeForm from "./subComponents/GradeForm";
import {
  handleCheckboxChange,
  addHandleChange,
  validateForm,
  deleteGrade,
  handleClick,
} from "./../../../utils/courseForm/courseFormUtils";

const syllabusList = ["Cambridge", "Edexcel", "AQA"];

function AddNewCourse({ onClose, grades, setLoading }) {
  const [activeButton, setActiveButton] = useState();
  const [formData, setFormData] = useState({
    subjectName: "",
    grades: [
      {
        level: "",
        fee: syllabusList.map(() => ({
          syllabus: "",
          visible: false,
          localFee: "",
          internationalFee: "",
          isNew: true, // Add a flag to mark newly entered data
        })),
      },
    ],
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  useEffect(() => {
    const selectedGrades = formData.grades.map((grade) => grade.level);
    setDisabledGrades(selectedGrades);
  }, [formData]);

  const [disabledGrades, setDisabledGrades] = useState([]);

  const addNewGrade = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grades: [
        ...prevFormData.grades,
        {
          level: "",
          fee: syllabusList.map(() => ({
            syllabus: "",
            visible: false,
            localFee: "",
            internationalFee: "",
            isNew: true,
          })),
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(formData, setPopupText, setShowPopup)) return;

    addCourse(setLoading, formData, setPopupText, setShowPopup);
  };

  return (
    <div className="add-new-course-tab">
      <div className="add-new-content">
        <div className="header">
          <div className="lhs">
            <h2>All Courses</h2>
            <FaAngleRight className="greater-icon" />
            <h3>Add New Course</h3>
          </div>
          <IoCloseSharp className="close-icon" onClick={onClose} />
        </div>
        <div className="content">
          <form onSubmit={handleSubmit}>
            <CourseDetailsForm formData={formData} setFormData={setFormData} />
            <GradeForm
              formData={formData}
              disabledGrades={disabledGrades}
              syllabusList={syllabusList}
              grades={grades}
              handleClick={(label, index) =>
                handleClick(label, index, formData, setFormData, setActiveButton)
              }
              handleCheckboxChange={(gradeIndex, syllabusIndex) =>
                handleCheckboxChange(
                  formData,
                  setFormData,
                  gradeIndex,
                  syllabusIndex
                )
              }
              handleChange={(e, gradeIndex, syllabusIndex) =>
                addHandleChange(
                  e,
                  formData,
                  setFormData,
                  gradeIndex,
                  syllabusIndex,
                  syllabusList
                )
              }
              deleteGrade={(index) =>
                deleteGrade(index, formData, setFormData)
              }
              addNewGrade={addNewGrade}
            />
            <div className="button-group">
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <Popup
          text={popupText}
          show={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default AddNewCourse;
