import React from "react";

function StudentGradeCard({ gradeData }) {
  return (
    <div className="grade-card">
      <div className="line"></div>
      <div className="details">
        <h2>{gradeData.level}</h2>
        <p>Enroll Count: {gradeData.enrollCount}</p>
        <div className="syllabus">
          {gradeData.fee.map((feeItem) => (
            <h3>{feeItem.syllabus}</h3>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StudentGradeCard;
