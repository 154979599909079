export const endPoints ={
    LOGIN:"/employee/login",
    USER_VERIFICATION:"/userVerification",
    GET_USER_INFO:"/employee/getByEmail",
    GET_STUDENT_INFO:"/student/getByEmail",
    UPDATE_STATUS_STUDENT:"/student/updateStatus",
    GET_EMPLOYEES:"/employee/",
    GET_STUDENTS:"/student/",
    GET_SUBJECTS:"/subject/",
    ADD_SUBJECT:"/subject/add-subject",
    EDIT_SUBJECT:"/subject/edit-subject/",
    ADD_ENROLLMENT:"/subject/enroll/",
    ADD_USER:"/employee/signin",
    ADD_STUDENT:"/student/signin",
    GET_EMPLOYEE:"/employee/getByEmail",
    UPDATE_STATUS_EMPLOYEE:"/employee/updateStatus",
    EDIT_USER_BY_OWN:"/employee/editByEmployee/",
    EMPLOYEE_ASSIGNED:"/employee/assignStudents/",
    EMPLOYEE_UPDATE_LINK:"/employee/updateLinkInfo/",
    EDIT_STUDENT_BY_OWN:"/student/editByStudent/",
    EDIT_USER_BY_ADMIN:"/employee/editByAdmin/",
    EDIT_STUDENT_BY_ADMIN:"/student/editByAdmin/",
    GET_GRADES:"/grade/",
    GET_CLASSES:"/class/",
    ADD_CLASSES:"/class/add-class/",
    REMOVE_CLASSES:"/class/remove-future-classes/",
    ADD_NOTIFICATION:"/notification/",
    GET_NOTIFICATION:"/notification/",
    CHANGE_PASSWORD:"/employee/changePassword",
    CHANGE_PASSWORD_DIRECT:"/employee/directChangePassword",
    EDIT_REMARK:"/class/edit-remark/",
    EDIT_CANCELLED_REMARK:"/class/edit-cancelled-remark/",
    EDIT_CLASS:"/class/edit-class/",
    EDIT_CLASS_IS_PAID:"/class/edit-isPaidToTeacher/",
    EDIT_CLASS_IS_PAID_BY_STUDENT:"/class/edit-isPaidByStudent/",
    EDIT_CLAS_STATUS:"/class/edit-status/",
    CALCULATE_PRICE:"/subject/calculate-fee"

}

