import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";

export const fetchSubjects = async (setSubjects) => {
    try {
        const response = await axiosInstance.get(endPoints.GET_SUBJECTS);
        setSubjects(response.data.subjects);
    } catch (error) {
        console.error("Error fetching subjects:", error);
    }
};