import React, { useState, useEffect } from "react";
import notificationImg from "../../assets/no-notification.jpg";
import image1 from "../../assets/bookImage.png";
import NotificationItemCard from "./NotificationItemCard";
import { Loader } from "../loader/Loader";

export const NotificationMenu = ({ notification, user }) => {
  const [activeTab, setActiveTab] = useState("Unread");
  const [notifications, setNotifications] = useState(notification || []);

  useEffect(() => {
    setNotifications(notification || []);
  }, [notification]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  const toggleNotificationStatus = (id, newStatus) => {
    setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
            notification._id === id
                ? { ...notification, status: newStatus }
                : notification
        )
    );
  };

  const filteredNotifications = notifications.filter((notification) =>
      activeTab === "All" ? true : !notification.status
  );

  if (!user || !notification) {
    return <Loader />;
  }

  return (
      <div className="notificationMenu-container">
        <div className="notification-table-container">
          <h1>Notifications</h1>
          <div className="notification-table">
            <div className="notification-menu">
              <h3
                  className={activeTab === "Unread" ? "active" : ""}
                  onClick={() => setActiveTab("Unread")}
              >
                Unread
              </h3>
              <h3
                  className={activeTab === "All" ? "active" : ""}
                  onClick={() => setActiveTab("All")}
              >
                All
              </h3>
            </div>

            <div className="notification-list">
              {filteredNotifications.length === 0 ? (
                  <div className="no-notifications">
                    <img src={notificationImg} alt="Avatar" className="avatar" />
                    <p>
                      {activeTab === "Unread"
                          ? "No unread notifications found"
                          : "No notifications"}
                    </p>
                  </div>
              ) : (
                  <>
                    <div className="timeline-line"></div>
                    <div className="timeline-top-circle"></div>
                    <div className="timeline-bottom-circle"></div>
                    {filteredNotifications
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((notification, index) => (
                            <NotificationItemCard
                                user={user}
                                id={notification._id}
                                key={index}
                                index={index}
                                image1={image1}
                                name={notification.topic}
                                heading={notification.topic}
                                note={notification.data}
                                date={formatDate(notification.date)}
                                status={notification.status}
                                toggleStatus={toggleNotificationStatus}
                            />
                        ))}
                  </>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};
