import React, {useState} from "react";
import {FaSearch} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import {IoMdEye, IoMdAdd} from "react-icons/io";
import {AddStudentForm} from "./AddStudentForm";
import {EditFormStudent} from "./EditFormStudent";
import ViewStudentDetails from "./ViewStudentDetails";
import {axiosInstance} from "../../../utils/axiosInstance";
import {endPoints} from "../../../utils/endPoints";
import {IoClose, IoWarningOutline} from "react-icons/io5";
import {updateStudentStatus} from "../../../utils/apis/Students";

function Student({
                     subjects,
                     grades,
                     students,
                     setIsSidePanelOpen,
                     setIsLoading,
                     user,
                 }) {
    const [sidePanelContent, setSidePanelContent] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [viewPopupContent, setViewPopupContent] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [studentToDelete, setStudentToDelete] = useState(null);

    const getUniqueSubjects = (subjects) => {
        const uniqueSubjectsSet = new Set(subjects.map((subject) => subject.name));
        return Array.from(uniqueSubjectsSet);
    };

    const handleAddNew = () => {
        setSidePanelContent(
            <div className="side-panel-content">
                <h2>Add New Student</h2>
                <AddStudentForm
                    subjects={subjects}
                    grades={grades}
                    handleCancel={() => {
                        setSidePanelContent(null);
                        setIsSidePanelOpen(false);
                    }}
                    handleSubmitCancel={() => {
                        setSidePanelContent(null);
                        setIsSidePanelOpen(false);
                        window.location.reload();
                    }}
                    setIsLoading={setIsLoading}
                />
            </div>
        );
        setIsSidePanelOpen(true);
    };

    const handleEdit = (student) => {
        setSidePanelContent(
            <div className="side-panel-content">
                <h2>Edit Details</h2>
                <EditFormStudent
                    subjects={subjects}
                    grades={grades}
                    student={student}
                    setIsLoading={setIsLoading}
                    handleCancel={() => {
                        setSidePanelContent(null);
                        setIsSidePanelOpen(false);
                    }}
                    handleSubmitCancel={() => {
                        setSidePanelContent(null);
                        setIsSidePanelOpen(false);
                        window.location.reload();
                    }}
                />
            </div>
        );
        setIsSidePanelOpen(true);
    };

    const handleStatusChange = (student, newStatus) => {
        if (newStatus === "inactive") {
            setStudentToDelete(student);
            setShowConfirmation(true);
        } else if (newStatus === "active") {
            updateStudentStatus(student, newStatus,setIsLoading);
        }
    };


    const handleView = (student) => {
        setViewPopupContent(
            <div>
                <ViewStudentDetails
                    subjects={subjects}
                    grades={grades}
                    student={student}
                    onClose={() => setViewPopupContent(null)}
                />
            </div>
        );
    };

    const filteredStudents = user.role === 3002
        ? students.filter((student) => user.assigned.includes(student.email))
        : students;

    const sortedStudents = filteredStudents.sort((a, b) => {
        if (a.status === "active" && b.status !== "active") return -1;
        if (a.status !== "active" && b.status === "active") return 1;

        return a.stuId - b.stuId;
    });

    return (
        <>
            <div className="add-new-content">
                {(user.role === 1000 || user.role === 3000 || user.role === 3001 || user.role ===3003) && (
                    <div onClick={handleAddNew} className="add-new">
                        + &nbsp; <span>New Student</span>
                    </div>
                )}
            </div>
            <div className={`employee-content`}>
                <table className="employee-table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Current Grade</th>
                        <th>Subjects</th>
                        <th>Email</th>
                        {(user.role === 1000 || user.role === 3000 || user.role === 3001 || user.role === 3003) && <th>Action</th>}
                        {(user.role === 1000 || user.role === 3000) && <th>Status</th>}
                    </tr>
                    </thead>
                    <tbody>
                        {sortedStudents.map((student, index) => (
                            <tr key={index}>
                                <td>{student.stuId}</td>
                                <td>
                                    {student.name} {student.lname}
                                </td>
                                <td>{student.grade}</td>
                                <td>
                                    {student.subjects.length > 0 ? (
                                        <span key={student._id}>
                                            {getUniqueSubjects(student.subjects).map(
                                                (subject, subjectIndex) => (
                                                    <span key={subjectIndex}>
                                                        {subject}
                                                        {subjectIndex <
                                                            getUniqueSubjects(student.subjects).length - 1 &&
                                                            ", "}
                                                    </span>
                                                )
                                            )}
                                        </span>
                                    ) : (
                                        <span>No subjects added</span>
                                    )}
                                </td>
                                <td>{student.email}</td>
                                {(user.role === 1000 || user.role === 3000 || 3003) && (
                                    <td className="action-icons">
                                        <MdEdit
                                            onClick={() => handleEdit(student)}
                                            className="icon"
                                        />
                                        <IoMdEye
                                            className="icon"
                                            onClick={() => handleView(student)}
                                        />
                                    </td>
                                )}
                                <td>
                                    {( user.role === 1000 || user.role === 3000 || user.role === 3001) && (
                                        <span
                                            className={`status ${student?.status
                                                ?.replace(" ", "")
                                                .toLowerCase()}`}
                                        >
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={student.status === "active"}
                                                    onChange={(e) =>
                                                        handleStatusChange(student, e.target.checked ? "active" : "inactive")
                                                    }
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {sidePanelContent && (
                    <div className="side-panel">{sidePanelContent}</div>
                )}

                {viewPopupContent && (
                    <div className="view-popup">{viewPopupContent}</div>
                )}

                {showConfirmation && (
                    <div className="overlay">
                        <div className="confirmation-popup">
                            <div className="modal-header">
                                <div className="lhs">
                                    <h2><IoWarningOutline /> &nbsp;Warning </h2>
                                </div>
                                <IoClose className="close-icon" onClick={() => setShowConfirmation(false)} />
                            </div>
                            <div className="body-part">
                                <p>
                                    Are you sure you want to deactivate this student?
                                </p>
                                <div className="popup-buttons">
                                    <button
                                        onClick={() => {
                                            setShowConfirmation(false);
                                            updateStudentStatus(
                                                studentToDelete,
                                                "inactive",
                                                setIsLoading
                                            );
                                        }}
                                    >
                                        Yes, deactivate
                                    </button>
                                    <button onClick={() => setShowConfirmation(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Student;
