import {Loader} from "../loader/Loader";
import React, {useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";
import {FaSearch} from "react-icons/fa";
import {PaymentOutput} from "./PaymentOutput";
import {ThreeDots} from "react-loader-spinner";
import {months} from "../../utils/helperFunctions/months"
import SearchContainer from "../searchBar/SearchContainer";

const initialFilters = {
  teachers: [],
  students: [],
  years: [],
  isPaidToTeacher: [],
  isPaidByStudent: [],
  months: [],
};

export const Payment = ({
  classes,
  employees,
  grades,
  subjects,
  students,
  user,
}) => {
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filters, setFilters] = useState(initialFilters);
  const [collapsedSections, setCollapsedSections] = useState({
    students: true,
    teachers: true,
    payment: true,
    months: true,
    years: true,
  });
  const [activeTab, setActiveTab] = useState("student");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const getStudentById = (stuId) => {
    return students.find(student => student.stuId === stuId) || { name: "Unknown Student", type: "Unknown" };
  };


  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      toggleSortOrder();
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (
        filterType === "isPaidByStudent" ||
        filterType === "isPaidToTeacher"
      ) {
        updatedFilters[filterType] = updatedFilters[filterType].includes(value)
          ? []
          : [value];
      } else {
        if (updatedFilters[filterType].includes(value)) {
          updatedFilters[filterType] = updatedFilters[filterType].filter(
            (v) => v !== value
          );
        } else {
          updatedFilters[filterType] = [...updatedFilters[filterType], value];
        }
      }

      return updatedFilters;
    });
  };

  const toggleSection = (section) => {
    setCollapsedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const isFiltered = (item) => {
    const itemYear = new Date(item.date).getFullYear();
    const itemMonth = new Date(item.date).getMonth();
    const paymentStatusFilter =
        activeTab === "employee"
            ? filters.isPaidToTeacher
            : filters.isPaidByStudent;

    const isMatchingSearch = (term, field) =>
        field && field.toLowerCase().includes(term.trim().toLowerCase());

    const itemPaymentStatus =
        activeTab === "employee" ? item.isPaidToTeacher : item.isPaidByStudent;

    const student = getStudentById(item.student);
    const studentName = student.name;
    const studentId=student.stuId

    return (
        (!filters.teachers.length || filters.teachers.includes(item.teacher)) &&
        (!filters.students.length || filters.students.includes(studentName)) &&
        (!filters.years.length || filters.years.includes(itemYear)) &&
        (!paymentStatusFilter.length ||
            paymentStatusFilter.includes(itemPaymentStatus ? "true" : "false")) &&
        (!filters.months.length || filters.months.includes(months[itemMonth])) &&
        (activeTab === "employee"
            ? (isMatchingSearch(searchTerm, item.teacher))
            : (isMatchingSearch(searchTerm, studentName) || isMatchingSearch(searchTerm, studentId) ))
    );
  };

  const sortedClasses = [...classes].sort((a, b) => {
    let compare = 0;
    switch (sortCriteria) {
      case "teacher":
        compare = a.teacher.localeCompare(b.teacher);
        break;
      case "student":
        compare = getStudentById(a.student).name.localeCompare(getStudentById(b.student).name);
        break;
      case "month":
        compare = new Date(a.date).getMonth() - new Date(b.date).getMonth();
        break;
      case "year":
        compare =
            new Date(a.date).getFullYear() - new Date(b.date).getFullYear();
        break;
      case "paymentStatus":
        if (activeTab === "employee") {
          compare = (a.isPaidToTeacher ? 1 : 0) - (b.isPaidToTeacher ? 1 : 0);
        } else {
          compare = (a.isPaidByStudent ? 1 : 0) - (b.isPaidByStudent ? 1 : 0);
        }
        break;
      default:
        compare = new Date(a.date) - new Date(b.date);
        break;
    }
    return sortOrder === "asc" ? compare : -compare;
  });

  const filteredClasses = sortedClasses.filter(isFiltered);

  const handleResetClass = () => {
    setSelectedClasses([]);
  };

  const handleClassSelect = (classId) => {
    setSelectedClasses((prevSelected) =>
      prevSelected.includes(classId)
        ? prevSelected.filter((id) => id !== classId)
        : [...prevSelected, classId]
    );
  };

  const handlePaymentStatusChange = async (status) => {
    try {
      setIsLoading(true);
      const endpoint =
        activeTab === "employee"
          ? endPoints.EDIT_CLASS_IS_PAID
          : endPoints.EDIT_CLASS_IS_PAID_BY_STUDENT;

      const updatePayload =
        activeTab === "employee"
          ? { classIds: selectedClasses, isPaidToTeacher: status }
          : { classIds: selectedClasses, isPaidByStudent: status };

      const paymentResponse = await axiosInstance.put(endpoint, updatePayload);

      if (paymentResponse.data.status === "success") {
        console.log("Payment status updated successfully");
        setSelectedClasses([]);
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setIsLoading(false);
        console.error("Failed to update payment status");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating payment status:", error);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setFilters(initialFilters);
    setActiveTab(option);
    setDropdownOpen(false);
  };

  const isPaymentStatusFilterActive =
    activeTab === "employee"
      ? filters.isPaidToTeacher.length > 0
      : filters.isPaidByStudent.length > 0;

  const hasSelectedClasses = selectedClasses.length > 0;

  if (!employees || !classes || !students) {
    return <Loader />;
  }

  return (
    <div className="payment-details-container">
      {isLoading && (
        <ThreeDots
          visible={true}
          height="100"
          width="100"
          color="#8a70d6"
          secondaryColor="#E9E3FF"
          radius="20"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass="custom-loader-wrapper"
        />
      )}
      {activeTab === "student" && <h2>Student Payment Details</h2>}
      {activeTab === "employee" && <h2>Employee Payment Details</h2>}
      <div className="tabs">
      <SearchContainer 
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm} 
                />
        <div className="custom-dropdown">
          <div className="dropdown-toggle" onClick={handleDropdownToggle}>
            {activeTab === "student" ? "Students" : "Employees"}
          </div>
          {dropdownOpen && (
            <div className="dropdown-options">
              <div
                className="dropdown-option"
                onClick={() => handleOptionSelect("student")}
              >
                Students
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleOptionSelect("employee")}
              >
                Employees
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="payment-container">
        <div className="filter-container">
          <h2>Filtered By</h2>
          <div className="filter-section">
            <div
              className="filter-head"
              onClick={() => toggleSection("payment")}
              style={{ cursor: "pointer" }}
            >
              <h3>Payment Status</h3>
              <h3>{collapsedSections.payment ? "▼" : "▲"}</h3>
            </div>

            {!collapsedSections.payment &&
              (activeTab === "employee" ? (
                <>
                  <div>
                    <input
                      type="checkbox"
                      id="isPaidToTeacher-true"
                      checked={filters.isPaidToTeacher.includes("true")}
                      onChange={() =>
                        handleFilterChange("isPaidToTeacher", "true")
                      }
                    />
                    <label htmlFor="isPaidToTeacher-true">Paid</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="isPaidToTeacher-false"
                      checked={filters.isPaidToTeacher.includes("false")}
                      onChange={() =>
                        handleFilterChange("isPaidToTeacher", "false")
                      }
                    />
                    <label htmlFor="isPaidToTeacher-false">Not Paid</label>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <input
                      type="checkbox"
                      id="isPaidByStudent-true"
                      checked={filters.isPaidByStudent.includes("true")}
                      onChange={() =>
                        handleFilterChange("isPaidByStudent", "true")
                      }
                    />
                    <label htmlFor="isPaidByStudent-true">Paid</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="isPaidByStudent-false"
                      checked={filters.isPaidByStudent.includes("false")}
                      onChange={() =>
                        handleFilterChange("isPaidByStudent", "false")
                      }
                    />
                    <label htmlFor="isPaidByStudent-false">Not Paid</label>
                  </div>
                </>
              ))}
          </div>
          {activeTab === "employee" && (
            <div className="filter-section">
              <div
                className="filter-head"
                onClick={() => toggleSection("teachers")}
                style={{ cursor: "pointer" }}
              >
                <h3>Tutors</h3>
                <h3>{collapsedSections.teachers ? "▼" : "▲"}</h3>
              </div>
              {!collapsedSections.teachers &&
                employees
                  .filter((emp) => emp.role === 3002)
                  .map((teacher, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={`teacher-${index}`}
                        checked={filters.teachers.includes(teacher.name)}
                        onChange={() =>
                          handleFilterChange("teachers", teacher.name)
                        }
                      />
                      <label htmlFor={`teacher-${index}`}>{teacher.name}</label>
                    </div>
                  ))}
            </div>
          )}
          <div className="filter-section">
            <div
              className="filter-head"
              onClick={() => toggleSection("years")}
              style={{ cursor: "pointer" }}
            >
              <h3>Years</h3>
              <h3>{collapsedSections.years ? "▼" : "▲"}</h3>
            </div>

            {!collapsedSections.years &&
              Array.from(
                new Set(classes.map((cls) => new Date(cls.date).getFullYear()))
              ).map((year, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`year-${index}`}
                    checked={filters.years.includes(year)}
                    onChange={() => handleFilterChange("years", year)}
                  />
                  <label htmlFor={`year-${index}`}>{year}</label>
                </div>
              ))}
          </div>
          <div className="filter-section">
            <div
              className="filter-head"
              onClick={() => toggleSection("months")}
              style={{ cursor: "pointer" }}
            >
              <h3>Months</h3>
              <h3>{collapsedSections.months ? "▼" : "▲"}</h3>
            </div>
            {!collapsedSections.months &&
              months.map((month, index) => (
                <div key={month}>
                  <input
                    type="checkbox"
                    id={`month-${index}`}
                    checked={filters.months.includes(month)}
                    onChange={() => handleFilterChange("months", month)}
                  />
                  <label htmlFor={`month-${index}`}>{month}</label>
                </div>
              ))}
          </div>
        </div>
        <PaymentOutput
          filteredClasses={filteredClasses}
          handleClassSelect={handleClassSelect}
          handleSort={handleSort}
          hasSelectedClasses={hasSelectedClasses}
          selectedClasses={selectedClasses}
          sortOrder={sortOrder}
          sortCriteria={sortCriteria}
          isPaymentStatusFilterActive={isPaymentStatusFilterActive}
          filters={filters}
          handlePaymentStatusChange={handlePaymentStatusChange}
          activeTab={activeTab}
          handleResetClass={handleResetClass}
          students={students}
          employees={employees}
        />
      </div>
    </div>
  );
};
