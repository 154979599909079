import React from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import SyllabusDropdown from "./SyllabusDropdown";

const GradeForm = ({
  formData,
  disabledGrades,
  syllabusList,
  grades,
  handleClick,
  handleCheckboxChange,
  handleChange,
  deleteGrade,
  addNewGrade,
}) => {
  return (
    <div className="grade-form-section">
      <div className="heading">
        <h3>Grades</h3>
      </div>
      {formData.grades.map((grade, compIndex) => (
        <div key={compIndex} className="repeating-content">
          <div className="btns">
            <div className="grade-btns">
              {grades.map((gradeObj, index) => (
                <button
                  key={index}
                  className={`button ${
                    gradeObj.grade === grade.level ? "active" : ""
                  }`}
                  onClick={() => handleClick(gradeObj.grade, compIndex)}
                  type="button"
                  disabled={
                    disabledGrades.includes(gradeObj.grade) &&
                    gradeObj.grade !== grade.level
                  }
                >
                  {gradeObj.grade}
                </button>
              ))}
            </div>
            {formData.grades.length > 1 && (
              <AiTwotoneDelete
                className="delete-icon"
                onClick={() => deleteGrade(compIndex)}
              />
            )}
          </div>
          <div className="syllabus">
            {syllabusList.map((syllabus, index) => (
              <SyllabusDropdown
                key={index}
                index={index}
                syllabus={syllabus}
                isSyllabusVisible={grade.fee[index]?.visible}
                handleCheckboxChange={() =>
                  handleCheckboxChange(compIndex, index)
                }
                course={grade.fee[index]}
                handleCourseChange={(e) =>
                  handleChange(e, compIndex, index)
                }
              />
            ))}
          </div>
        </div>
      ))}
      <div className="add-grade-btn">
        <button type="button" onClick={addNewGrade}>
          Add another Grade +
        </button>
      </div>
    </div>
  );
};

export default GradeForm;
