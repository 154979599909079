import React from 'react';

function AdminGradeCard({ gradeData }) {
  return (
    <div className="grade-card">
      <div className="line"></div>
      <div className="details">
        <h2>{gradeData.level}</h2>
        <p>Enroll Count: {gradeData.enrollCount}</p>
        
        <div className="fee-table">
          {gradeData.fee.map((feeItem) => (
            <div className="fee">
              <h3 className="fee-syllabus">{feeItem.syllabus}</h3>
                {
                    feeItem.localFee &&
                    (<div className="fee-type">
                        <h3>Local (Rs)</h3>
                        <h3>{feeItem.localFee.toFixed(2)}</h3>
                    </div>)
                }
                {
                    feeItem.internationalFee &&
                    (<div className="fee-type">
                        <h3>International ($)</h3>
                        <h3>{feeItem.internationalFee.toFixed(2)}</h3>
                    </div>)
                }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminGradeCard;
