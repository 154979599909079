import image1 from "../../../assets/bookImage.png";
import React, { useEffect } from "react";
import { PopularCourseCard } from "./PopularCourseCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const PopularCourses = ({ subjects, userSubjects }) => {
  console.log("User Subjects:", userSubjects);

  const filteredSubjects = subjects.filter((subjectObj) =>
    userSubjects.some(
      (userSubject) => userSubject.name === subjectObj.subjectName
    )
  );

  console.log("Filtered Subjects:", filteredSubjects);
  console.log("All Subjects:", subjects);

  useEffect(() => {
    console.log("Filtered Subjects on Render:", filteredSubjects);
  }, [filteredSubjects]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 4,
    },
    mediumDesktop: {
      breakpoint: { max: 2000, min: 1500 },
      items: 3,
    },
    smallDesktop: {
      breakpoint: { max: 1500, min: 1200 },
      items: 2,
    },
    largeTablet: {
      breakpoint: { max: 1200, min: 1024 },
      items: 2,
    },
    mediumTablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    smallTablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="dashboard-component">
      <h2>My Subjects</h2>
      {filteredSubjects.length > 0 ? (
        <Carousel
          responsive={responsive}
          infinite={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          className="popular-courses"
        >
          {filteredSubjects.map((subject, index) => (
            <PopularCourseCard
              key={index}
              subject={subject.subjectName}
              code={subject.code}
              image={image1}
            />
          ))}
        </Carousel>
      ) : (
        <p>No subjects available</p>
      )}
    </div>
  );
};
