import React, {useState} from "react";
import {EditProfileForm} from "./EditProfileForm";
import {Loader} from "../loader/Loader";
import {ThreeDots} from "react-loader-spinner";

export const Settings = ({ user, subjects, grades }) => {
  const [activeForm, setActiveForm] = useState("editProfile");
  const [loading, setLoading] = useState(false);

  const renderForm = () => {
    switch (activeForm) {
      case "editProfile":
        return (
          <EditProfileForm
            user={user}
            subjects={subjects}
            setLoading={setLoading}
          />
        );
      default:
        return null;
    }
  };

  if (!user || !subjects || !grades) {
    return <Loader />;
  }

  return (
    <div className="settings">
      {loading && (
        <ThreeDots
          visible={true}
          height="100"
          width="100"
          color="#8a70d6"
          secondaryColor="#E9E3FF"
          radius="20"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass="custom-loader-wrapper"
        />
      )}
      <div className="button-group"></div>
      <div className="form-container">{renderForm()}</div>
    </div>
  );
};
