import LoginBanner from '../assets/login/login-banner.png';
import Logo from "../assets/logo/Tuto-1.png"
import  MinLogo from "../assets/logo/Tuto-2.png"
import LogoWhite from "../assets/logo/Tuto-W1.png"
import  MinLogoWhite from "../assets/logo/Tuto-W2.png"
import GirlOne from  "../assets/avatar/girl_1.jpg"
import GirlTwo from  "../assets/avatar/girl_2.jpg"
import GirlThree from  "../assets/avatar/girl_3.jpg"
import BoyOne from  "../assets/avatar/boy_1.jpg"
import BoyTwo from  "../assets/avatar/boy_2.jpg"
import BoyThree from  "../assets/avatar/boy_3.jpg"
import PatternOne from  "../assets/patterns/pattern_1.jpg"
import PatternTwo from  "../assets/patterns/pattern_2.jpg"
import PatternThree from  "../assets/patterns/pattern_3.jpg"
import PatternFour from  "../assets/patterns//pattern_4.jpg"

export const images = {
    LoginBanner,
    Logo,
    MinLogo,
    LogoWhite,
    MinLogoWhite,
    boy:[
        BoyOne,
        BoyTwo,
        BoyThree
    ],
    girl:[
        GirlOne,
        GirlTwo,
        GirlThree
    ],
    patterns:[
        PatternOne,
        PatternTwo,
        PatternThree,
        PatternFour
    ]
}