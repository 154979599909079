import React, {useEffect, useState} from "react";
import {FaBell, FaBook, FaCalendarAlt} from "react-icons/fa";
import {IoMdSettings} from "react-icons/io";
import {MdDashboard, MdPayment} from "react-icons/md";
import "react-calendar/dist/Calendar.css";
import "../index.scss";
import {images} from "../utils/images";
import {Dashboard} from "./dashboard/Dashboard";
import {Settings} from "./settings/Settings";
import AllClasses from "./allClasses/AllClasses";
import DataSetUp from "./dataSetUp/DatasetUp";
import {FaListUl} from "react-icons/fa6";
import {NotificationMenu} from "./notificationMenu/NotificationMenu";
import AllCourses from "./allCourses/AllCourses";
import CalendarEventsPro from "./calendarEvents/CalendarEventsPro";
import MobileView from './mobileView/MobileView'
import {Payment} from "./payment/Payment";
import {fetchClasses} from "../utils/apis/Class";
import {fetchUserData} from "../utils/apis/userData";
import {fetchGrades} from "../utils/apis/Grades";
import {fetchSubjects} from "../utils/apis/Subjects";
import {fetchEmployees} from "../utils/apis/EmployeeApi";
import {fetchStudents} from "../utils/apis/Students";
import {fetchNotification} from "../utils/apis/Notification";

function AdminPanel() {
  const [isOpen, setIsOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const [user, setUser] = useState();
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [classes, setClasses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [students, setStudents] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }

    fetchUserData(setUser,setLoading);
    fetchSubjects(setSubjects);
    fetchGrades(setGrades);
    fetchClasses(setClasses);
    fetchEmployees(setEmployees);
    fetchStudents(setStudents);
  }, []);

  useEffect(() => {
    fetchNotification(user,setNotifications);
  }, [user]);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const navigateToSettings = () => {
    setActiveTab("settings");
  };

  const navigateToNotification = () => {
    setActiveTab("notification");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("activeTab");
    window.location.reload();
  };

  const openLogoutPopup = () => {
    setShowLogoutPopup(true);
  };

  const closeLogoutPopup = () => {
    setShowLogoutPopup(false);
  };

  return (
      <>
        <div className="container">
          <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
            <div className="toggle-btn" onClick={toggleSidebar}>
              {isOpen ? (
                  <>
                    <img className="main-logo" src={images.LogoWhite} />
                  </>
              ) : (
                  <img className="min-logo" src={images.MinLogoWhite} />
              )}
            </div>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "85%",
                }}
            >
              <div className="menu">
                <div
                    className={`menu-item ${(activeTab === "dashboard" || activeTab === "") ? "active" : ""}`}
                    onClick={() => setActiveTab("dashboard")}
                >
                  <MdDashboard className="icon" />
                  {isOpen && <span>Dashboard</span>}
                </div>
                <div
                    className={`menu-item ${activeTab === "notification" ? "active" : ""}`}
                    onClick={() => setActiveTab("notification")}
                >
                  <FaBell className="icon" />
                  {isOpen && <span>Notification</span>}
                </div>
                <div
                    className={`menu-item ${activeTab === "allClasses" ? "active" : ""}`}
                    onClick={() => setActiveTab("allClasses")}
                >
                  <FaBook className="icon" />
                  {isOpen && <span>All Classes</span>}
                </div>
                <div
                    className={`menu-item ${activeTab === "timetable" ? "active" : ""}`}
                    onClick={() => setActiveTab("timetable")}
                >
                  <FaCalendarAlt className="icon" />
                  {isOpen && <span>Calendar</span>}
                </div>
                <div
                    className={`menu-item ${activeTab === "allCourses" ? "active" : ""}`}
                    onClick={() => setActiveTab("allCourses")}
                >
                  <FaBook className="icon" />
                  {isOpen && <span>All Courses</span>}
                </div>
                <div
                    className={`menu-item ${activeTab === "datasetup" ? "active" : ""}`}
                    onClick={() => setActiveTab("datasetup")}
                >
                  <FaListUl className="icon" />
                  {isOpen && <span>Data Setup</span>}
                </div>
                {user && (user.role === 1000 || user.role === 3000 || user.role === 3001) && (
                    <div
                        className={`menu-item ${activeTab === "payment" ? "active" : ""}`}
                        onClick={() => setActiveTab("payment")}
                    >
                      <MdPayment className="icon" />
                      {isOpen && <span>Payment</span>}
                    </div>
                )}
                <div
                    className={`menu-item ${activeTab === "settings" ? "active" : ""}`}
                    onClick={() => setActiveTab("settings")}
                >
                  <IoMdSettings className="icon" />
                  {isOpen && <span>Settings</span>}
                </div>
              </div>
            </div>
          </div>

          <div className={`main-content ${isOpen ? "sidebar-open" : ""}`}>
            {(activeTab === "dashboard" || activeTab === "") && (
                <Dashboard
                    user={user}
                    subjects={subjects}
                    navigateToSettings={navigateToSettings}
                    navigateToNotification={navigateToNotification}
                    classes={classes}
                    notifications={notifications}
                    openLogOut={openLogoutPopup}
                />
            )}

            {activeTab === "notification" && (
                <NotificationMenu notification={notifications} user={user} />
            )}

            {activeTab === "allClasses" && (
                <AllClasses
                    classes={classes}
                    employees={employees}
                    subjects={subjects}
                    grades={grades}
                    students={students}
                    user={user}
                />
            )}

            {activeTab === "timetable" && (
                <CalendarEventsPro
                    classes={classes}
                    employees={employees}
                    subjects={subjects}
                    grades={grades}
                    students={students}
                    user={user}
                />
            )}

            {activeTab === "allCourses" && (
                <AllCourses grades={grades} subjects={subjects} user={user} />
            )}

            {activeTab === "datasetup" && (
                <DataSetUp
                    subjects={subjects}
                    grades={grades}
                    employees={employees}
                    students={students}
                    user={user}
                />
            )}

            {user && (user.role === 1000 ||user.role === 3000 || user.role === 3001) && activeTab === "payment" && (
                <Payment
                    classes={classes}
                    employees={employees}
                    subjects={subjects}
                    grades={grades}
                    students={students}
                    user={user}
                />
            )}

            {activeTab === "settings" && (
                <Settings user={user} subjects={subjects} grades={grades} />
            )}

            {showLogoutPopup && (
                <div className="logout-popup">
                  <div className="logout-popup-content">
                    <h3>Are you sure you want to log out?</h3>
                    <div className="logout-popup-buttons">
                      <button onClick={handleLogout}>Logout</button>
                      <button onClick={closeLogoutPopup}>Cancel</button>
                    </div>
                  </div>
                </div>
            )}
          </div>
        </div>
        <MobileView />
      </>
  );
}

export default AdminPanel;
