import React, {useState} from "react";
import TagsInput from "./tagsInput/TagsInput";
import {axiosInstance} from "../../utils/axiosInstance";
import {endPoints} from "../../utils/endPoints";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {images} from "../../utils/images";
import Popup from "../popup/Popup";


export const EditProfileForm = ({ user, subjects, setLoading }) => {
  let profileImage = null;
  if (user.gender === "Male" && user.picture.startsWith("boy")) {
    const index = parseInt(user.picture.split("_")[1]) - 1;
    profileImage = images.boy[index];
  } else if (user.gender === "Female" && user.picture.startsWith("girl")) {
    const index = parseInt(user.picture.split("_")[1]) - 1;
    profileImage = images.girl[index];
  }

  const [formData, setFormData] = useState({
    _id: user?._id,
    empId: user?.empId || "",
    name: user?.name || "",
    lname: user?.lname || "",
    email: user?.email || "",
    gender: user?.gender || "",
    assigned: user?.assigned || [],
    dateOfBirth: user?.dateOfBirth
      ? new Date(user.dateOfBirth).toISOString().split("T")[0]
      : "",
    phoneNumber: user?.phoneNumber || "",
    subjects: user?.subjects || [],
    picture: user?.picture || "",
    role: user?.role || "",
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Form Data:", formData);
    try {
      const updateData = { ...formData };
      const response = await axiosInstance.put(
        `${endPoints.EDIT_USER_BY_OWN}${formData.email}`,
        updateData
      );
      setPopupText("Profile details updated successfully!");
      setShowPopup(true);
      console.log("Profile updated:", response.data);
    } catch (error) {
      setPopupText("Failed to update profile. Please try again!");
      setShowPopup(true);
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPopupText("New passwords do not match.");
      setShowPopup(true);
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.put(endPoints.CHANGE_PASSWORD, {
        email: formData.email,
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      });
      console.log("Password changed:", response.data);

      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });

      // Show success popup
      setPopupText("Password changed successfully!");
      setShowPopup(true);
    } catch (error) {
      console.error("Error changing password:", error);
      setPopupText("Failed to change password. Please try again!");
      setShowPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <>
    <div className="edit-content">
      <div style={{ display: "flex" }}>
        <form onSubmit={handleSubmit} className="edit-form">
          <h2>Edit Profile</h2>
          <div className="personal">
            <div className="profile-img">
              {profileImage && (
                <img src={profileImage} alt="Profile" className="image" />
              )}
            </div>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="lname"
                value={formData.lname}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Gender:
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </label>
            <label>
              Date of Birth:
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                disabled
                required
              />
            </label>
            <label>
              Phone Number:
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </label>
            { user?.role === 3004 && (
              <label>
                Syllabus:
                <select
                  name="syllabus"
                  value={formData.syllabus}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Syllabus</option>
                  <option value="Edexcel">Edexcel</option>
                  <option value="Cambridge">Cambridge</option>
                  <option value="AQA">AQA</option>
                </select>
              </label>
            )}
            {
              formData.subjects.length!==0 && (
                    <label>
                      Subjects:
                      <TagsInput
                          tags={formData.subjects}
                          setTags={(newTags) =>
                              setFormData({...formData, subjects: newTags})
                          }
                          subjects={subjects}
                          user={user}
                          editable={false}
                      />
                    </label>
                )
            }
            <div className="btn">
              <button type="submit" className="full-width">
                Save
              </button>
            </div>
          </div>
        </form>
        <form onSubmit={handleChangePassword} className="edit-form">
          <div className="password-change">
            <h2>Change Password</h2>
            <label>
              Current Password
              <input
                type={passwordVisibility.current ? "text" : "password"}
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={handlePasswordChange}
                required
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility("current")}
              >
                {passwordVisibility.current ? <FaEye /> : <FaEyeSlash />}
              </span>
            </label>
            <label>
              New Password
              <input
                type={passwordVisibility.new ? "text" : "password"}
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                required
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility("new")}
              >
                {passwordVisibility.new ? <FaEye /> : <FaEyeSlash />}
              </span>
            </label>
            <label>
              Confirmed Password
              <input
                type={passwordVisibility.confirm ? "text" : "password"}
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility("confirm")}
              >
                {passwordVisibility.confirm ? <FaEye /> : <FaEyeSlash />}
              </span>
            </label>
            <div className="btn">
              <button type="submit" className="full-width">
                Change
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <Popup
    show={showPopup}
    text={popupText}
    onClose={() => setShowPopup(false)}
  />
    </>
  );
};

export default EditProfileForm;
