import React, { useState } from "react";
import { IoMdEye } from "react-icons/io";
import { IoIosDownload } from "react-icons/io";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaAngleRight } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";

export const PaymentOutput = ({
                                isPaymentStatusFilterActive,
                                hasSelectedClasses,
                                handleSort,
                                sortCriteria,
                                sortOrder,
                                filteredClasses,
                                selectedClasses,
                                handleClassSelect,
                                filters,
                                handlePaymentStatusChange,
                                activeTab,
                                handleResetClass,
                                students,
                                employees
                              }) => {
  const [allSelected, setAllSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const getStudentById = (stuId) => {
    return (
        students.find((student) => student.stuId === stuId) || {
          name: "Unknown Student",
          type: "Unknown",
        }
    );
  };

  const getEmployeeByEmail = (email) => {
    return (
        employees.find((employee) => employee.email === email) || {
          name: "Unknown Employee",
          type: "Unknown",
        }
    );
  };

  const handleSelectAll = () => {
    if (allSelected) {
      handleResetClass();
      setAllSelected(false);
    } else {
      filteredClasses.map((cls) => handleClassSelect(cls.classId));
      setAllSelected(true);
    }
  };

  const filteredApprovedClasses = filteredClasses.filter(
      (cls) => !cls.isTrial && cls.status === "approved"
  );

  const openPopup = (cls) => {
    setPopupContent(cls);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent(null);
  };

  const calculateHours = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const diff = (end - start) / (1000 * 60 * 60);
    return Math.ceil(diff);
  };

  function determineCurrency(studentId) {
    const student = getStudentById(studentId);

    const currency = student.type === "International" ? "$" : "Rs.";

    return currency;
  }

  function getStudentFullName(studentId) {
    if (!studentId) return "Unknown Student";

    const student = getStudentById(studentId);

    if (!student || !student.name || !student.lname) {
      return "Unknown Student";
    }

    const fullName = `${student.name} ${student.lname}`;
    return fullName;
  }

  function getEmployeeFullName(studentId) {
    if (!studentId) return "Unknown Student";

    const student = getStudentById(studentId);

    if (!student || !student.name || !student.lname) {
      return "Unknown Student";
    }

    const fullName = `${student.name} ${student.lname}`;
    return fullName;
  }



  const downloadPDF = () => {
    const doc = new jsPDF();
    const student = getStudentById(popupContent.student);
    const currency = student.type === "International" ? "$" : "Rs.";

    const hours = calculateHours(popupContent.startTime, popupContent.endTime);
    const pricePerHour = popupContent.price / hours;
    const subTotal = popupContent.price;

    doc.text("Invoice", 20, 20);
    doc.text("Class Details", 20, 30);

    const headers = [["Label", "Details"]];
    const data = [
      ["Student ID", popupContent.student],
      ["Student Name", student.name],
      ["Subject", popupContent.subject],
      ["Teacher", popupContent.teacher],
      ["Grade", popupContent.grade],
      ["Date", new Date(popupContent.date).toLocaleDateString()],
      ["Fee", `${currency} ${popupContent.price}`],
    ];

    doc.autoTable({
      head: headers,
      body: data,
      startY: 40,
    });

    doc.text("Invoice Summary", 20, doc.autoTable.previous.finalY + 10);
    const tableHeaders = [["Subject", "Price", "Hours", "SubTotal"]];
    const tableData = [
      [
        popupContent.subject,
        `${currency} ${pricePerHour.toFixed(2)}`,
        hours,
        `${currency} ${subTotal}`,
      ],
    ];

    doc.autoTable({
      head: tableHeaders,
      body: tableData,
      startY: doc.autoTable.previous.finalY + 20,
    });

    doc.save("invoice.pdf");
  };

  return (
      <div className="output-container">
        {isPaymentStatusFilterActive && hasSelectedClasses && (
            <>
              {(activeTab === "employee"
                  ? filters.isPaidToTeacher.includes("false")
                  : filters.isPaidByStudent.includes("false")) && (
                  <button onClick={() => handlePaymentStatusChange(true)}>
                    Mark as Paid
                  </button>
              )}
              {(activeTab === "employee"
                  ? filters.isPaidToTeacher.includes("true")
                  : filters.isPaidByStudent.includes("true")) && (
                  <button onClick={() => handlePaymentStatusChange(false)}>
                    Mark as Unpaid
                  </button>
              )}
            </>
        )}
        <table>
          <thead>
          <tr>
            {(isPaymentStatusFilterActive || hasSelectedClasses) && (
                <th>
                  <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={handleSelectAll}
                  />
                </th>
            )}
            <th>Subject</th>
            <th
                onClick={() => handleSort("teacher")}
                style={{ cursor: "pointer" }}
            >
              Tutor{" "}
              {sortCriteria === "teacher" &&
                  (sortOrder === "asc" ? "▲" : "▼")}
            </th>
            <th>Student ID</th>
            <th>Student</th>
            <th>Grade</th>
            <th
                onClick={() => handleSort("date")}
                style={{ cursor: "pointer" }}
            >
              Date
              {sortCriteria === "date" && (sortOrder === "asc" ? "▲" : "▼")}
            </th>
            <th>Fee</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {filteredApprovedClasses.map((cls) => {
            const student = getStudentById(cls.student);
            const currency = student.type === "International" ? "$" : "Rs.";
            return (
                <tr key={cls.classId}>
                  {(isPaymentStatusFilterActive || hasSelectedClasses) && (
                      <td>
                        <input
                            type="checkbox"
                            checked={selectedClasses.includes(cls.classId)}
                            onChange={() => handleClassSelect(cls.classId)}
                        />
                      </td>
                  )}
                  <td>{cls.subject}</td>
                  <td>{cls.teacher}</td>
                  <td>{cls.student}</td>
                  <td>{student.name}</td>
                  <td>{cls.grade}</td>
                  <td>{new Date(cls.date).toLocaleDateString()}</td>
                  <td>
                    {currency}
                    {(
                        cls.price / calculateHours(cls.startTime, cls.endTime)
                    ).toFixed(2)}
                  </td>
                  <td>
                    <IoMdEye className="icon" onClick={() => openPopup(cls)}/>
                  </td>
                </tr>
            );
          })}
          </tbody>
        </table>

        {isPopupOpen && (
            <div className="popup-overlay">
              <div className="popup-content">
                <div className="header">
                  <div className="lhs">
                    <h2>INVOICE</h2>
                    <FaAngleRight className="greater-icon" />
                    <h3>{popupContent.classId}</h3>
                  </div>
                  <IoCloseSharp
                      className="close-icon"
                      onClick={() => setIsPopupOpen(false)}
                  />
                </div>

                <div className="popup-body">
                  <div className="invoice-header">
                    <div className="circle"></div>
                    <h2>INVOICE</h2>
                  </div>
                  <div className="details-container">

                    {
                      activeTab === "student" ?
                          <p style={{fontWeight: "500"}}>
                            {getStudentFullName(popupContent.student)}
                          </p> :
                          <p style={{fontWeight: "500"}}>
                            {`${getEmployeeByEmail(popupContent.teacherEmail).name} ${getEmployeeByEmail(popupContent.teacherEmail).lname} `}
                          </p>
                    }

                    <p>
                      Class Date:
                      <span>
                    {new Date(popupContent.date).toLocaleDateString()}
                  </span>
                    </p>
                    <p>
                      Class Id: <span>{popupContent.classId}</span>
                    </p>
                  </div>

                  <table>
                    <thead>
                    <tr>
                      <th style={{textAlign: "left"}}>Subject</th>
                      <th>Grade</th>
                      <th>Price</th>
                      <th>Hours</th>
                      <th style={{textAlign: "right"}}>SubTotal</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td style={{textAlign: "left"}}>{popupContent.subject}</td>
                      <td>
                        {popupContent.grade}
                      </td>
                      <td>
                        {determineCurrency(popupContent.student)}
                        {(
                            popupContent.price /
                            calculateHours(popupContent.startTime, popupContent.endTime)
                        ).toFixed(2)}
                      </td>
                      <td>{calculateHours(popupContent.startTime, popupContent.endTime)}</td>
                      <td style={{textAlign: "right"}}>{determineCurrency(popupContent.student)}{popupContent.price}</td>
                    </tr>
                    </tbody>
                  </table>


                  <table>
                    <thead>
                    <tr>
                      <th style={{textAlign: "left"}}>Paid Status</th>
                      <th  style={{textAlign: "left"}}>Paid Date</th>
                      <th style={{textAlign: "left"}}>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                      {
                        activeTab === "student" ?
                            <td style={{textAlign: "left"}}>{popupContent.isPaidByStudent ? "Yes" : "No"}</td> :
                            <td style={{textAlign: "left"}}>{popupContent.isPaidToTeacher ? "Yes" : "No"}</td>
                      }

                      {
                        activeTab === "student" ?
                            <td style={{textAlign: "left"}}>{ new Date(popupContent.paidByStudentDate).toLocaleDateString() ?? "-"}</td> :
                            <td style={{textAlign: "left"}}>{ new Date(popupContent.paidToTeacherDate).toLocaleDateString() ?? "-" }</td>
                      }
                      <td style={{textAlign: "left"}}>{determineCurrency(popupContent.student)}{popupContent.price}</td>
                    </tr>
                    </tbody>
                  </table>


                  {/*{*/}
                  {/*  popupContent.isPaidByStudent &&*/}
                  {/*    (*/}
                  {/*        <div>*/}
                  {/*        </div>*/}
                  {/*    )*/}
                  {/*}*/}


                  {/*<div className="btn">*/}
                  {/*  <button onClick={downloadPDF}>*/}
                  {/*    Download <IoIosDownload />*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
        )}
      </div>
  );
};
