import React, { useState } from "react";
import { axiosInstance } from "../../../utils/axiosInstance";
import { endPoints } from "../../../utils/endPoints";
import TagsInput from "../../settings/tagsInput/TagsInput";
import { PiArrowClockwiseFill } from "react-icons/pi";
import { images } from "../../../utils/images";
import Popup from "../../popup/Popup";
import {roleMapping} from "../../../utils/helperFunctions/role";
import {addEmployee} from "../../../utils/apis/EmployeeApi";


export const AddEmployeeForm = ({
  subjects,
  grades,
  handleCancel,
  setIsLoading,
  handleSubmitCancel
}) => {
  const [formData, setFormData] = useState({
    name: "",
    lname: "",
    email: "",
    gender: "",
    address:"",
    nic:"",
    onboardedDate:"",
    dateOfBirth: "",
    passwordHash: "",
    phoneNumber: "",
    subjects: [],
    picture: "",
    role: ""
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

  };

  const generatePassword = () => {
    const password = Math.random().toString(36).slice(-8);
    setFormData({ ...formData, passwordHash: password });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.passwordHash) {
      setPopupText("Please generate a password before submitting.");
      setShowPopup(true);
      return;
    }

    addEmployee(setIsLoading, formData, setPopupText, setShowPopup, handleSubmitCancel);
  };

  return (
    <>
    <form onSubmit={handleSubmit} className="form-container">
      <div className="cols">
        <div className="form-first-column">
          <label>
            Name:*
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Last Name:*
            <input
              type="text"
              name="lname"
              value={formData.lname}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:*
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Gender:*
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label>
            Date of Birth:*
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone Number:*
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>

          {/*<label>*/}
          {/*  Picture URL:*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    name="picture"*/}
          {/*    value={formData.picture}*/}
          {/*    onChange={handleChange}*/}
          {/*  />*/}
          {/*</label>*/}
        </div>

        <div className="form-second-column">

          <label>
            Address:
            <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
            />
          </label>

          <label>
            Nic:
            <input
                type="text"
                name="nic"
                value={formData.nic}
                onChange={handleChange}

            />
          </label>

          <label>
            Onboarded Date:
            <input
                type="date"
                name="onboardedDate"
                value={formData.onboardedDate}
                onChange={handleChange}

            />
          </label>

          <label>
            Role:*
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <option value="">Select Role</option>
              <option value="HR">HR</option>
              <option value="Admin">Admin</option>
              <option value="Teacher">Teacher</option>
              <option value="StudentConsultant">Student Consultant</option>
            </select>
          </label>

          <label>
            Password:*
            <div className="password-container">
              <input
                type="text"
                name="passwordHash"
                value={formData.passwordHash}
                readOnly
                required
              />
              <PiArrowClockwiseFill
                onClick={generatePassword}
                className="password-icon"
              />
            </div>
          </label>
          <label>
            Subjects:
            <TagsInput
              tags={formData.subjects}
              setTags={(newTags) =>
                setFormData({ ...formData, subjects: newTags })
              }
              subjects={subjects}
              editable={true}
              grades={grades}
            />
          </label>
        </div>
      </div>
      <div className="btns">
        <button onClick={handleCancel}>Cancel</button>
        <button type="submit" className="full-width">
          Save
        </button>
      </div>
    </form>
    <Popup
    show={showPopup}
    text={popupText}
    onClose={() => setShowPopup(false)}
  />
    </>
  );
};
