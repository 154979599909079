import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosCopy } from "react-icons/io";
import Popup from "../../popup/Popup";
import { images } from "../../../utils/images";
import {reverseRoleMapping} from "../../../utils/helperFunctions/role";
import {getUserImage} from "../../../utils/helperFunctions/helperFunctions";


export const ViewStudentDetails = ({ student, onClose }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(null);
    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  const [showPopup, setShowPopup] = useState(false);


  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };
  const copyEmailToClipboard = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log("Email copied to clipboard");
        setShowPopup(true);

        if (popupTimeout) {
          clearTimeout(popupTimeout);
        }

        setPopupTimeout(setTimeout(() => {
          setShowPopup(false);
        }, 2000));
      })
      .catch((error) => {
        console.error("Failed to copy email: ", error);
      });
  };
  return (
    <div className="view-student-tab">
      <div className="view-student-content">
        <div className="header">
          <div className="lhs">
            <h2>Student Information</h2>
            <FaAngleRight className="greater-icon" />
            <h3>
              {student.name} {student.lname}
            </h3>
          </div>
          <IoCloseSharp className="close-icon" onClick={onClose} />
        </div>
        <div className="student-details">
          <h3 className="admin-tag">{reverseRoleMapping[student.role]}</h3>
          <div className="main-details">
            <div className="image-container">
              {getUserImage(student) && (
                <img src={getUserImage(student)} alt="Profile" className="image" />
              )}
            </div>
            <div className="details">
              <div className="row-one">
                <div className="row-group">
                  <p>Name </p>
                  <p>:</p>
                  <h3>
                    {student.name} {student.lname}
                  </h3>
                </div>
                <div className="row-group">
                  <p>Email </p>
                  <p>:</p>
                  <h3>
                    {student.email}
                    <IoIosCopy
                      onClick={() => copyEmailToClipboard(student.email)}
                    />
                  </h3>
                </div>
              </div>

              <div className="row-two">
                <div className="col-one">
                  <div className="row-group">
                    <p>ID</p>
                    <p>:</p>
                    <h3>{student.stuId}</h3>
                  </div>
                  <div className="row-group">
                    <p>Date of Birth </p>
                    <p>:</p>
                    <h3>{formatDate(student.dateOfBirth)}</h3>
                  </div>
                </div>
                <div className="col-one">
                  <div className="row-group">
                    <p>Phone No </p>
                    <p>:</p>
                    <h3>{student.phoneNumber}</h3>
                  </div>
                  <div className="row-group">
                    <p>Gender </p>
                    <p>:</p>
                    <h3>{student.gender}</h3>
                  </div>
                </div>
              </div>

              <div className="row-two">
                <div className="col-one">
                  { student.joinedDate &&
                      <div className="row-group">
                        <p>Joined Date</p>
                        <p>:</p>
                        <h3>{formatDate(student.joinedDate)}</h3>
                      </div>
                  }
                </div>
              </div>


            </div>
          </div>
          <div className="view-more" onClick={toggleDetails}>
            {isExpanded ? " " : "More Info"}
          </div>
          {isExpanded && (
            <div className="parent-details">
              <h3>Parent Details</h3>
              <div className="row-one">
                <div className="row-group">
                  <p> Name </p>
                  <p>:</p>
                  <h3>{student.parentDetails.name}</h3>
                </div>
                <div className="row-group">
                  <p> Phone No </p>
                  <p>:</p>
                  <h3>{student.parentDetails.phoneNumber}</h3>
                </div>
              </div>
              <div className="row-group">
                <p> Email </p>
                <p>:</p>
                <h3>{student.parentDetails.email}</h3>
              </div>
              <div className="view-less" onClick={toggleDetails}>
                {isExpanded ? "Less Info" : " "}
              </div>
            </div>
          )}
        </div>

        <div className="other-details">
          <div className="row-group">
            <p> Grade </p>
            <p>:</p>
            <h3>{student.grade}</h3>
          </div>
          <div className="row-group">
            <p> Syllabus </p>
            <p>:</p>
            <h3>{student.syllabus}</h3>
          </div>
          <div className="row-group">
            <p> Type </p>
            <p>:</p>
            <h3>{student.type}</h3>
          </div>
        </div>

        <div className="subjects">
          <h3>Subjects</h3>
          <div className="subject-list">
            {student.subjects.map((subject) => (
              <div className="subject" key={subject.name}>
                {subject.name}-{subject.grade}
              </div>
            ))}
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup text={"Email copied to Clipboard!"} show={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </div>
  );
};

export default ViewStudentDetails;
