import { FaBell } from "react-icons/fa";
import Calendar from "react-calendar";
import React, { useState } from "react";
import { PopularCourses } from "./popularCourses/PopularCourses";
import { MyCourses } from "./mycourses/MyCourses";
import { Loader } from "../loader/Loader";
import { IoClose } from "react-icons/io5";
import { Notification } from "./notification/Notification";
import image1 from "../../assets/bookImage.png";
import { LuUserCog } from "react-icons/lu";
import { PiSignOut } from "react-icons/pi";
import { images } from "../../utils/images";
import Report from "./report/Report";
import {reverseRoleMapping} from "../../utils/helperFunctions/role";



export const Dashboard = ({
  user,
  subjects,
  navigateToSettings,
  navigateToNotification,
  classes,
  notifications,
  openLogOut,
}) => {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const openLogoutPopup = () => {
    setShowLogoutPopup(true);
  };

  const closeLogoutPopup = () => {
    setShowLogoutPopup(false);
  };

  const toggleNotifications = (lenght) => {
    if (lenght === 0) {
      navigateToNotification();
    }
    setShowNotifications((prevState) => !prevState);
  };

  const toggleProfile = () => {
    setShowProfile((prevState) => !prevState);
  };

  const closeNotificationPopup = () => {
    setShowNotifications(false);
  };
  const closeProfilePopup = () => {
    setShowProfile(false);
  };

  const toggleOptions = () => {
    setShowOptions((prevState) => !prevState);
  };

  const markAllAsRead = () => {
    // Implement the logic to mark all notifications as read
    console.log("All notifications marked as read");
    setShowOptions(false); // Close the options menu after marking as read
  };

  if (!user || !subjects || !classes || !notifications) {
    return <Loader />;
  }

  let profileImage = null;
  if (user.gender === "Male" && user.picture.startsWith("boy")) {
    const index = parseInt(user.picture.split("_")[1]) - 1;
    profileImage = images.boy[index];
  } else if (user.gender === "Female" && user.picture.startsWith("girl")) {
    const index = parseInt(user.picture.split("_")[1]) - 1;
    profileImage = images.girl[index];
  }

  return (
    <div className="content-dashboard">
      <div className="header">
        <h1>Dashboard</h1>
        <div className="header-right">
          <div className="bell-container">
            <p className="count">
              {
                notifications.filter((notification) => !notification.status)
                  .length
              }
            </p>
            <FaBell
              className="icon"
              onClick={() =>
                toggleNotifications(
                  notifications.filter((notification) => !notification.status)
                    .length
                )
              }
            />
          </div>
          <h3>
            {user.name} {user.lname}
          </h3>
          <div className="profile-circle-container" onClick={toggleProfile}>
            {profileImage && <img src={profileImage} alt="Profile" />}
          </div>
        </div>
      </div>

      {showNotifications && (
        <div className="notification-popup">
          <div className="notification-popup-content">
            <div className="header">
              <h3>Notifications</h3>
              <IoClose
                className="icon-notification"
                onClick={closeNotificationPopup}
              />
            </div>
            {showOptions && (
              <div className="options-menu">
                <button onClick={markAllAsRead}>Mark as All Read</button>
                <button onClick={markAllAsRead}>Delete All</button>
              </div>
            )}
            {notifications
              .filter((notification) => !notification.status)
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .slice(0, 4)
              .map((notification, index) => (
                <Notification key={index} notification={notification} />
              ))}
            <button onClick={navigateToNotification}>More...</button>
          </div>
        </div>
      )}

      {showProfile && (
        <div className="profile-popup">
          <div className="profile-popup-content">
            <div className="profile-header">
              {/*<IoClose className="icon-notification" onClick={closeProfilePopup} />*/}
              <h3>{user.name}</h3>
              <p>{reverseRoleMapping[user.role]}</p>
            </div>
            <div className="profile-options-menu">
              <button onClick={navigateToSettings}>
                <LuUserCog /> &nbsp; Profile
              </button>
              <button onClick={openLogOut}>
                <PiSignOut /> &nbsp; Sign Out
              </button>
            </div>
          </div>
        </div>
      )}

      {showLogoutPopup && (
        <div className="logout-popup">
          <div className="logout-popup-content">
            <h3>Are you sure you want to log out?</h3>
            <div className="logout-popup-buttons">
              <button onClick={handleLogout}>Logout</button>
              <button onClick={closeLogoutPopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      <div className="dashboard-component-main">
        <div className="first-row">
          {(user.role === 3002 || user.role === 3004 ) && (
            <PopularCourses subjects={subjects} userSubjects={user.subjects} />
          )}
          {(user.role === 1000 || user.role === 3001 || user.role === 3000 ) && (
           <Report classes={classes}/>
          )}
          <Calendar />
        </div>

        <div className="second-row">
          <MyCourses courses={classes} user={user} />
        </div>
      </div>
    </div>
  );
};
