import React from "react";

const CourseDetailsForm = ({ formData, setFormData }) => {
  return (
    <div className="course-form-section">
      <div className="heading">
        <h3>Course Details</h3>
      </div>
      <div className="fields">
        <div className="form-group">
          <label>Subject Name</label>
          <input
            type="text"
            name="subjectName"
            value={formData.subjectName}
            onChange={(e) =>
              setFormData({ ...formData, subjectName: e.target.value })
            }
          />
        </div>
        {/* <div className="form-group">
          <label>Code</label>
          <input
            type="text"
            name="code"
            value={formData.code}
            onChange={(e) =>
              setFormData({ ...formData, code: e.target.value })
            }
          />
        </div> */}
      </div>
    </div>
  );
};

export default CourseDetailsForm;
