import image from "../../../assets/bookImage.png";
import React from "react";

export const Notification = ({ notification }) => {
  const getShortMessage = (message, wordLimit) => {
    const words = message.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : message;
  };

  return (
    <div className="each-notification">
      <img src={image} alt="avatar" className="avatar" />
      <div className="notification-text">
        <div className="notification-title">
          <strong>{notification.topic} 🎉</strong>
        </div>
        <div className="notification-message">
          {getShortMessage(notification.data, 5)}
        </div>
        <div className="notification-time">
          {new Date(notification.date).toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};
