import React from "react";

function SyllabusDropdown({
  index,
  isSyllabusVisible,
  handleCheckboxChange,
  course,
  handleCourseChange,
  syllabus,
}) {
  return (
    <div className="each-syllabus">
      <div className="main-col">
        {syllabus}
        <input
          name={syllabus}
          type="checkbox"
          className="custom-checkbox"
          checked={isSyllabusVisible}
          onChange={handleCheckboxChange}
        />
      </div>
      {isSyllabusVisible && (
        <div className="dropdown">
          <div className="dropdown-item">
            Local Fee (Rs)
            <input
              type="text"
              name="localFee"
              value={course.localFee}
              onChange={handleCourseChange}
              placeholder="Price"
            />
          </div>
          <div className="dropdown-item">
            International Fee ($)
            <input
              type="text"
              name="internationalFee"
              value={course.internationalFee}
              onChange={handleCourseChange}
              placeholder="Price"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SyllabusDropdown;
