import React, { useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEventModal from "./AddEventModel";
import { axiosInstance } from "../../utils/axiosInstance";
import { endPoints } from "../../utils/endPoints";
import CalendarEventModal from "./CalendarEventModal";
import EditEventModel from "./EditEventModel";
import Popup from "../popup/Popup";
import { Loader } from "../loader/Loader";
import {roleMapping} from "../../utils/helperFunctions/role";
import {sendNotification, sendNotifications} from "../../utils/apis/Notification";
import {addTutorLink} from "../../utils/apis/EmployeeApi";
import {editClass} from "../../utils/apis/Class";
import {calculateFee, createRecurrenceDates} from "../../utils/helperFunctions/helperFunctions";

const localizer = momentLocalizer(moment);


const CalendarEventsPro = ({
                             classes = [],
                             grades = [],
                             employees = [],
                             subjects = [],
                             students = [],
                             user,
                           }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  const teachers = employees.filter(
      (employee) => employee.role === roleMapping["Teacher"]
  );
  const studentConsultants = employees.filter(
      (employee) => employee.role === roleMapping["StudentConsultant"]
  );

  const getColorByStatus = (status) => {
    switch (status) {
      case "pending":
        return "#dcb06e";
      case "approved":
        return "#77DD77";
      case "cancelled":
        return "#FF6961";
      case "incomplete":
        return "#FF7518";
      default:
        return "#8a70d6";
    }
  };

  const filterClasses = () => {

    if (!user || !user.role) {
      return classes;
    }

    switch (user.role) {
      case 3004:
        return classes.filter((course) => course.studentEmail === user.email);
      case 3002:
        return classes.filter((course) => course.teacherEmail === user.email);
      case 3003:
        return classes.filter(
            (course) =>
                course.studentConsultantEmail === user.email
        );
      default:
        return classes;
    }
  };

  const filteredClasses = filterClasses();

  const events = filteredClasses.map((classEvent) => {
    const teacher = employees.find((emp) => emp.name === classEvent.teacher);
    const student = employees.find((emp) => emp.name === classEvent.student);
    return {
      title: classEvent.title,
      start: new Date(
          `${classEvent.date.split("T")[0]}T${classEvent.startTime}:00`
      ),
      end: new Date(
          `${classEvent.date.split("T")[0]}T${classEvent.endTime}:00`
      ),
      allDay: false,
      color: getColorByStatus(classEvent.status),
      ...classEvent,
      teacherEmail: teacher ? teacher.email : "Unknown Email",
      studentName: student ? student.name : "Unknown Student",
    };
  });

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };


  const handleAddEvent = async (event, recursion) => {
    try {
      const student = students.find(
          (student) => student.email === event.studentEmail
      );

      if (!student) {
        console.error("Student not found");
        setPopupText("Student not found in the system. Please try again!");
        setShowPopup(true);
        return;
      }

      const subject = subjects.find(
          (sub) => sub.subjectName === event.subject
      );

      if (!subject) {
        console.error("Subject not found");
        setPopupText("Subject not found in the system. Please try again!");
        setShowPopup(true);
        return;
      }

      const feeAmount=calculateFee(event,subject,student,setPopupText,setShowPopup)

      if (recursion === "Does Not Repeat") {
        await sendNotifications(event,event.date,feeAmount,employees,setPopupText,setShowPopup);
      } else {
        const startDate = new Date(event.date);
        const recurrenceDates = createRecurrenceDates(startDate, recursion);

        for (const date of recurrenceDates) {
          await sendNotifications(event,date.toISOString().split('T')[0],feeAmount,employees,setPopupText,setShowPopup);
        }
      }

      setPopupText("Event added successfully!");
      setShowPopup(true);

      addTutorLink(event)

      setShowModal(false);
    } catch (error) {
      console.error("Error adding event:", error);
      setPopupText("Failed to add event. Please try again!");
      setShowPopup(true);
    }
  };

  const handleEditEvent =  (event) => {
   editClass(event,employees,setPopupText,setShowPopup,setShowEditModal)
  };

  const eventPropGetter = (event) => {
    const backgroundColor = event.color;
    return { style: { backgroundColor } };
  };

  if (!user || !subjects || !classes || !grades || !employees || !students ) {
    return <Loader />;
  }

  return (
      <>
        <div className="calendar-container">
          <div className="event-button-container">
            {(user.role !== 3004) && (
                <button
                    onClick={() => setShowModal(true)}
                    className="add-event-button"
                >
                 + Add Class
                </button>
            )}
          </div>
          <div style={{ height: "500px" }}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "100%" }}
                onSelectEvent={handleEventClick}
                eventPropGetter={eventPropGetter}
                defaultView="agenda"
                popup
            />
            <div className="status">
              <div className="status-item">
                <div className="small-circle" style={{backgroundColor: "#77DD77"}}></div>
                -
                <p>Approved</p>
              </div>

              <div className="status-item">
                <div className="small-circle" style={{backgroundColor: "#dcb06e"}}></div>
                -
                <p>Pending</p>
              </div>

              <div className="status-item">
                <div className="small-circle" style={{backgroundColor: "#FF6961"}}></div>
                -
                <p>Cancelled</p>
              </div>


              <div className="status-item">
                <div className="small-circle" style={{backgroundColor: "#FF7518"}}></div>
                -
                <p>Incomplete</p>
              </div>
            </div>
          </div>

          {selectedEvent && (
              <CalendarEventModal
                  event={selectedEvent}
                  isOpen={modalIsOpen}
                  onClose={closeModal}
                  handleOpenEditModal={() => setShowEditModal(true)}
                  user={user}
                  students={students}
              />
          )}

          {showModal && (
              <AddEventModal
                  onAddEvent={handleAddEvent}
                  onClose={() => setShowModal(false)}
                  teachers={teachers}
                  students={students}
                  subjects={subjects}
                  grades={grades}
                  studentConsultants={studentConsultants}
                  user={user}
              />
          )}
          {showEditModal && (
              <EditEventModel
                  teachers={teachers}
                  students={students}
                  subjects={subjects}
                  grades={grades}
                  event={selectedEvent}
                  onEditEvent={handleEditEvent}
                  onClose={() => setShowEditModal(false)}
                  studentConsultants={studentConsultants}
                  user={user}
              />
          )}

        </div>

        <Popup
            show={showPopup}
            text={popupText}
            onClose={() => setShowPopup(false)}
        />

      </>
  );
};

export default CalendarEventsPro;
