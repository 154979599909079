import React, {useState} from "react";
import {MdEdit} from "react-icons/md";
import {IoMdEye} from "react-icons/io";
import {AddEmployeeForm} from "./AddEmployeeForm";
import {EditFormEmployee} from "./EditFormEmploiyee";
import {ViewEmployeeDetails} from "./ViewEmployeeDetails";
import {Loader} from "../../loader/Loader";
import {IoClose, IoWarningOutline} from "react-icons/io5";
import {updateEmployeeStatus} from "../../../utils/apis/EmployeeApi";


import boy1 from "../../../assets/avatar/boy_1.jpg";
import boy2 from "../../../assets/avatar/boy_2.jpg";
import boy3 from "../../../assets/avatar/boy_3.jpg";
import girl1 from "../../../assets/avatar/girl_1.jpg";
import girl2 from "../../../assets/avatar/girl_2.jpg";
import girl3 from "../../../assets/avatar/girl_3.jpg";


const studentImages = {
  boy_1: boy1,
  boy_2: boy2,
  boy_3: boy3,
  girl_1: girl1,
  girl_2: girl2,
  girl_3: girl3,
  default: boy1
};

export const Employee = ({
  subjects,
  grades,
  employees,
  setIsSidePanelOpen,
  setIsLoading,
  user,
  students,
}) => {
  const [sidePanelContent, setSidePanelContent] = useState(null);
  const [viewPopupContent, setViewPopupContent] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const getUniqueSubjects = (subjects) => {
    const uniqueSubjectsSet = new Set(subjects.map((subject) => subject.name));
    return Array.from(uniqueSubjectsSet);
  };

  const handleAddNew = () => {
    setSidePanelContent(
      <div className="side-panel-content">
        <h2>Add New Employee</h2>
        <AddEmployeeForm
          subjects={subjects}
          grades={grades}
          handleCancel={() => {
            setSidePanelContent(null);
            setIsSidePanelOpen(false);
          }}
          handleSubmitCancel={() => {
            setSidePanelContent(null);
            setIsSidePanelOpen(false);
            window.location.reload();
          }}
          setIsLoading={setIsLoading}
        />
      </div>
    );
    setIsSidePanelOpen(true);
  };

  const handleEdit = (employee) => {
    setSidePanelContent(
      <div className="side-panel-content">
        <h2>Edit Details</h2>
        <EditFormEmployee
          subjects={subjects}
          employees={employee}
          grades={grades}
          setIsLoading={setIsLoading}
          handleCancel={() => {
            setSidePanelContent(null);
            setIsSidePanelOpen(false);
          }}
          handleSubmitCancel={() => {
            setSidePanelContent(null);
            setIsSidePanelOpen(false);
            window.location.reload();
          }}
        />
      </div>
    );
    setIsSidePanelOpen(true);
  };

  const handleView = (employee) => {
    setViewPopupContent(
      <div>
        <ViewEmployeeDetails
          subjects={subjects}
          employees={employee}
          grades={grades}
          onClose={() => setViewPopupContent(null)}
          students={students}
        />
      </div>
    );
  };

  const handleStatusChange = (employee, newStatus) => {
    if (newStatus === "inactive") {
      setEmployeeToDelete(employee);
      setShowConfirmation(true);
    } else if (newStatus === "active") {
      updateEmployeeStatus(employee, newStatus,setIsLoading);
    }
  };


  if (!employees || !user) {
    return <Loader />;
  }

  const filteredEmployees =
    user.role === 3002
      ? employees.filter(
          (employee) => employee.role === 3000 || employee.role === 3001
        )
      : employees;

  const sortedEmployees = filteredEmployees.sort((a, b) => {
    if (a.status === "active" && b.status !== "active") return -1;
    if (a.status !== "active" && b.status === "active") return 1;

    return a.empId - b.empId;
  });

  return (
    <>
      <div className="add-new-content">
        {( user.role === 1000 || user.role === 3000 || user.role === 3001) && (
          <div onClick={handleAddNew} className="add-new">
            + &nbsp; <span>New Employee</span>
          </div>
        )}
      </div>
      <div className="employee-content">
        <table className="employee-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              {user.role !== 3002 && <th>Assigned</th>}
              <th>Subjects</th>
              <th>Email</th>
              {(user.role === 1000 ||
                user.role === 3000 ||
                user.role === 3001 ) && <th>Action</th>}
              {(user.role === 1000 || user.role === 3000 || user.role === 3001) && <th>Status</th>}
              {user.role === 3002 && <th>Mobile Number</th>}
            </tr>
          </thead>
          <tbody>
            {sortedEmployees.map((employee, index) => (
              <tr key={index}>
                <td>{employee.empId}</td>
                <td>
                  {employee.name} {employee.lname}
                </td>
                {user.role !== 3002 && employee && (
                    <td>
                      <div className="avatars">
                        {employee.assigned?.slice(0, 3).map((studentEmail, index) => {
                          const student = students.find((s) => s.email === studentEmail);
                          console.log("Student:", student);

                          const imageSrc =
                              student && studentImages[student.picture]
                                  ? studentImages[student.picture]
                                  : studentImages.default;

                          return (
                              <img key={index} src={imageSrc} alt="Avatar" className="avatar" />
                          );
                        })}
                        {employee.assigned?.length > 3 && (
                            <span>+{employee.assigned.length - 3}</span>
                        )}
                      </div>
                    </td>
                )}

                <td>
                  {employee.subjects.length > 0 ? (
                    <span key={employee._id}>
                      {getUniqueSubjects(employee.subjects).map(
                        (subject, subjectIndex) => (
                          <span key={subjectIndex}>
                            {subject}
                            {subjectIndex <
                              getUniqueSubjects(employee.subjects).length - 1 &&
                              ", "}
                          </span>
                        )
                      )}
                    </span>
                  ) : (
                    <span>No subjects assigned</span>
                  )}
                </td>
                <td>{employee.email}</td>
                {(
                  user.role === 1000 ||
                  user.role === 3000 ||
                  user.role === 3001 ) && (
                  <td className="action-icons">
                    <MdEdit
                      onClick={() => handleEdit(employee)}
                      className="icon"
                    />
                    <IoMdEye
                      className="icon"
                      onClick={() => handleView(employee)}
                    />
                  </td>
                )}
                <td>
                  {(user.role === 1000 ||  user.role === 3000) && (
                    <span
                      className={`status ${
                        employee?.status?.replace(" ", "").toLowerCase() || ""
                      }`}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={employee.status === "active"}
                          onChange={(e) =>
                            handleStatusChange(
                              employee,
                              e.target.checked ? "active" : "inactive"
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </span>
                  )}
                { user.role ===3002 &&
                    <td>{employee.phoneNumber}</td>
                }
                  {user.role === 3001 && (
                    <span
                      className={`status ${employee?.status
                        .replace(" ", "")
                        .toLowerCase()}`}
                      style={{ fontWeight: "500" }}
                    >
                      <label className="switch">
                        {employee.status === "active" ? "Active" : "Inactive"}
                      </label>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {sidePanelContent && (
          <div className="side-panel">{sidePanelContent}</div>
        )}

        {viewPopupContent && (
          <div className="view-popup">{viewPopupContent}</div>
        )}

        {showConfirmation && (
          <div className="overlay">
            <div className="confirmation-popup">
              <div className="modal-header">
                <div className="lhs">
                  <h2>
                    <IoWarningOutline /> &nbsp;Warning{" "}
                  </h2>
                  {/*<FaAngleRight className="greater-icon" />*/}
                  {/*<h3>Add Class</h3>*/}
                </div>
                <IoClose
                  className="close-icon"
                  onClick={() => setShowConfirmation(false)}
                />
              </div>
              <div className="body-part">
                <p>Are you sure you want to deactivate this employee?</p>
                <div className="popup-buttons">
                  <button
                    onClick={() => {
                      setShowConfirmation(false);
                      updateEmployeeStatus(employeeToDelete, "inactive",setIsLoading);
                    }}
                  >
                    Yes, deactivate
                  </button>
                  <button onClick={() => setShowConfirmation(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
