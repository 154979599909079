import React, {useEffect, useState} from "react";
import CourseCard from "./CourseCard";
import {Loader} from "../loader/Loader";
import {FaSearch} from "react-icons/fa";
import image1 from "../../assets/bookImage.png";
import MoreInfoCourseTab from "./MoreInfoCourseTab";
import EditCourseTab from "./AddEditCourse/EditCourseTab";
import AddNewCourse from "./AddEditCourse/AddNewCourse";
import {ThreeDots} from "react-loader-spinner";

function AllCourses({ grades, subjects, user }) {
  const [filters, setFilters] = useState({ grades: [], subjects: [] });
  const [collapsedSections, setCollapsedSections] = useState({
    grades: true,
    subjects: true,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(null);
  const [editCourseTab, setEditCourseTab] = useState(null);
  const [addNewCourse, setAddNewCourse] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Filters updated:", filters);
  }, [filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType].includes(value)) {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (v) => v !== value
        );
      } else {
        updatedFilters[filterType] = [...updatedFilters[filterType], value];
      }
      return updatedFilters;
    });
  };

  const handleTagRemove = (filterType, value) => {
    handleFilterChange(filterType, value);
  };

  const isFiltered = (item) => {
    return (
      (!filters.grades.length || filters.grades.includes(item.grade)) &&
      (!filters.subjects.length ||
        filters.subjects.includes(item.subjectName)) &&
      (!searchTerm ||
        (item.subjectName &&
          item.subjectName.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  };

  const filteredCourses = subjects.filter(isFiltered).sort((a, b) => {
    return a.subjectName.localeCompare(b.subjectName);
  });

  const handleMoreDetailsClick = (course) => {
    setActiveTab(course);
  };
  const handleEditClick = (course) => {
    setEditCourseTab(course);
  };
  const handleCloseTab = () => {
    setActiveTab(null);
    setEditCourseTab(null);
    setAddNewCourse(null);
  };

  const handleAddNew = () => {
    setAddNewCourse(true);
  };

  if (!grades || !subjects || !user) {
    return <Loader />;
  }

  return (
    <div className="all-courses-container">
      {loading && (
        <ThreeDots
          visible={true}
          height="100"
          width="100"
          color="#8a70d6"
          secondaryColor="#E9E3FF"
          radius="20"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass="custom-loader-wrapper"
        />
      )}
      <h2>All Courses</h2>

      <div className="filters">
        <div className="search-container">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search Subject"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-section">
          {(user.role === 1000 || user.role === 3000 || user.role === 3001) && (
            <div onClick={handleAddNew} className="add-new">
              + &nbsp; <span>New Course</span>
            </div>
          )}
        </div>
      </div>

      <div className="selected-filters">
        {filters.grades.map((grade) => (
          <span key={grade} className="selected-filter">
            {grade}
            <button onClick={() => handleTagRemove("grades", grade)}>x</button>
          </span>
        ))}
        {filters.subjects.map((subject) => (
          <span key={subject} className="selected-filter">
            {subject}
            <button onClick={() => handleTagRemove("subjects", subject)}>
              x
            </button>
          </span>
        ))}
      </div>

      <div className="all-courses">
        <div className="course-list">
          {filteredCourses.length > 0
            ? filteredCourses.map((course) => (
                <CourseCard
                  key={course.code}
                  course={course}
                  onMoreDetailsClick={handleMoreDetailsClick}
                  onEditClick={handleEditClick}
                  user={user}
                />
              ))
            : null}
        </div>
      </div>

      {filteredCourses.length === 0 && (
        <div className="no-courses-found">
          <img src={image1} alt="Avatar" className="avatar" />
          <p>No courses found</p>
        </div>
      )}

      {activeTab && (
        <MoreInfoCourseTab
          course={activeTab}
          grades={grades}
          onClose={handleCloseTab}
          user={user}
        />
      )}

      {editCourseTab && (
        <EditCourseTab
          subjects={editCourseTab}
          grades={grades}
          onClose={handleCloseTab}
          setLoading={setLoading}
        />
      )}

      {addNewCourse && (
        <AddNewCourse
          course={addNewCourse}
          grades={grades}
          onClose={handleCloseTab}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}

export default AllCourses;
