import {axiosInstance} from "../axiosInstance";
import {endPoints} from "../endPoints";
import {images} from "../images";
import {roleMapping} from "../helperFunctions/role";
import {addImagesToUser} from "../helperFunctions/helperFunctions";

export
const fetchEmployees = async (setEmployees) => {
    try {
        const response = await axiosInstance.get(endPoints.GET_EMPLOYEES);
        setEmployees(response.data.employees);
    } catch (error) {
        console.error("Error fetching classes:", error);
    }
};

export const addEmployee =async (setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)=>{
    setIsLoading(true);
    console.log("Form Data:", formData);
    const roleId = roleMapping[formData.role];

    const updatedFormData={
        ...addImagesToUser(formData),
        role: roleId,
    }

    try {
        const response = await axiosInstance.post(
            endPoints.ADD_USER,
            updatedFormData
        );
        setPopupText("Employee Added Successfully!");
        setShowPopup(true);
        console.log("Employee Added:", response.data);
        setIsLoading(false);
        handleSubmitCancel()
    } catch (error) {
        setPopupText("Failed to add employee. Please try again!");
        setShowPopup(true);
        console.error("Error adding employee:", error);
        setIsLoading(false);
    }
}

export const editEmployee=async(setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)=>{
    setIsLoading(true);

    console.log("Form Data:", formData);
    try {
        const updateData = {
            ...formData,
        };

        const response = await axiosInstance.put(
            `${endPoints.EDIT_USER_BY_ADMIN}${formData.email}`,
            updateData
        );

        setPopupText("Employee Details Updated Successfully!");
        setShowPopup(true);
        console.log("Employee updated:", response.data);
        setIsLoading(false);
        handleSubmitCancel()
    } catch (error) {
        setPopupText("Failed to update Employee details. Please try again!");
        setShowPopup(true);
        setIsLoading(false);
        console.error("Error updating employee:", error);
    }
}

export const addTutorLink=async (event)=>{
    const teacherLink = await axiosInstance.put(
        `${endPoints.EMPLOYEE_UPDATE_LINK}${event.teacherEmail}`,
        {
            link: event.link,
        }
    );

    if (teacherLink.data.status === "success" || teacherLink.data.status === "No update needed") {
        console.log("Teacher link updated successfully.");

        setTimeout(() => {
            window.location.reload();
        }, 1000);

    }
}

export const updateEmployeeStatus = async (employee, newStatus,setIsLoading) => {
    try {
        setIsLoading(true);
        const response = await axiosInstance.put(
            `${endPoints.UPDATE_STATUS_EMPLOYEE}/${employee.email}`,
            {
                status: newStatus,
            }
        );

        if (response.data.status === "success") {
            console.log("Status updated:", response.data);
            if (employee.role === 3002 && newStatus === "inactive") {
                const classResponse = await axiosInstance.delete(
                    `${endPoints.REMOVE_CLASSES}${employee.email}`
                );
                if (classResponse.data.status === "success") {
                    console.log("Class removed");
                }
            }
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    } catch (error) {
        console.error("Error updating status:", error);
    }
};


