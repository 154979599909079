import React, { useState, useEffect } from "react";
import TagsInput from "../../settings/tagsInput/TagsInput";
import { axiosInstance } from "../../../utils/axiosInstance";
import { endPoints } from "../../../utils/endPoints";
import { Bars } from "react-loader-spinner";
import Popup from "../../popup/Popup";
import {editEmployee} from "../../../utils/apis/EmployeeApi";


export const EditFormEmployee = ({
  employees,
  grades,
  subjects,
  handleCancel,
  setIsLoading,
  handleSubmitCancel
}) => {
  const [formData, setFormData] = useState({
    _id: employees?._id,
    empId: employees?.empId || "",
    name: employees?.name || "",
    lname: employees?.lname || "",
    email: employees?.email || "",
    address:employees?.address || "",
    nic:employees?.nic || "",
    onboardedDate:employees?.onboardedDate || "",
    gender: employees?.gender || "",
    assigned: employees?.assigned || [],
    dateOfBirth: employees?.dateOfBirth
      ? new Date(employees.dateOfBirth).toISOString().split("T")[0]
      : "",
    phoneNumber: employees?.phoneNumber || "",
    subjects: employees?.subjects || [],
    picture: employees?.picture || "",
    role: employees?.role || "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    editEmployee(setIsLoading,formData,setPopupText,setShowPopup,handleSubmitCancel)
  };

  return (
    <>
    <form onSubmit={handleSubmit} className="form-container">
      <div className="cols">
        <div className="form-first-column">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="lname"
              value={formData.lname}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Gender:
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label>
            Date of Birth:
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone Number:
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>

        <div className="form-second-column">
          <label>
            Address:
            <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
            />
          </label>

          <label>
            Nic:
            <input
                type="text"
                name="nic"
                value={formData.nic}
                onChange={handleChange}

            />
          </label>

          <label>
            Onboarded Date:
            <input
                type="date"
                name="onboardedDate"
                value={formData.onboardedDate}
                onChange={handleChange}

            />
          </label>
          {/*{(employees?.role === 3002 || employees?.role === 3004) && (*/}
          {/*  <label>*/}
          {/*    Syllabus:*/}
          {/*    <select*/}
          {/*      name="syllabus"*/}
          {/*      value={formData.syllabus}*/}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <option value="">Select Syllabus</option>*/}
          {/*      <option value="Edexcel">Edexcel</option>*/}
          {/*      <option value="Cambridge">Cambridge</option>*/}
          {/*      <option value="AQA">AQA</option>*/}
          {/*    </select>*/}
          {/*  </label>*/}
          {/*)}*/}
          <label>
            Subjects:
            <TagsInput
              tags={formData.subjects}
              setTags={(newTags) =>
                setFormData({ ...formData, subjects: newTags })
              }
              subjects={subjects}
              editable={true}
              grades={grades}
            />
          </label>

        </div>
      </div>
      <div className="btns">
        <button onClick={handleCancel}>Cancel</button>
        <button type="submit" className="full-width">
          Save
        </button>
      </div>
    </form>
    <Popup
    show={showPopup}
    text={popupText}
    onClose={() => setShowPopup(false)}
  />
    </>
  );
};
