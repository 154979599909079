import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {FaAngleRight, FaBook, FaLink, FaMoneyCheckAlt} from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { MdDateRange } from "react-icons/md";
import { FaSchool } from "react-icons/fa6";
import { RiParentFill } from "react-icons/ri";
import {IoClose} from "react-icons/io5";

const EditEventModel = ({
  onEditEvent,
  onClose,
  teachers,
  students,
  subjects,
  grades,
  event,
  studentConsultants,
  user
}) => {
  const [formData, setFormData] = useState({
    classId: event?.classId || "",
    title: event?.title || "",
    link: event?.link || "",
    student: event?.student || "",
    studentEmail: event?.studentEmail || "",
    teacher: event?.teacher || "",
    teacherEmail: event?.teacherEmail || "",
    studentConsultant: event?.studentConsultant || "",
    studentConsultantEmail: event?.studentConsultantEmail || "",
    subject: event?.subject || "",
    date: event?.date || "",
    startTime: event?.startTime || "",
    endTime: event?.endTime || "",
    grade: event?.grade || "",
    price: event?.price || "",
    status: event?.status || "",
    isTeacherApproved: event?.isTeacherApproved || true,
    remark: event?.remark || "",
  });

  const isTeacher=user.role===3002

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (Udate) => {
    const date = moment.utc(Udate).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    console.log(date);
    setFormData({ ...formData, date });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const teacher = teachers.find((t) => t.name === formData.teacher);
    const student = students.find((s) => s.stuId === formData.student);
    const event = {
      ...formData,
      teacherEmail: teacher ? teacher.email : "Unknown Email",
      studentEmail: student ? student.email : "Unknown Email",
      grade:student.grade
    };
    onEditEvent(event);
  };

  const handleTimeChange = (e, type) => {
    const { value } = e.target;
    setFormData({ ...formData, [type]: value });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="lhs">
            <h2>Calendar</h2>
            <FaAngleRight className="greater-icon"/>
            <h3>Edit Class</h3>
          </div>
          <IoClose className="close-icon" onClick={onClose}/>
        </div>

        <form onSubmit={handleSubmit} className="event-form">
          <div className="title-container">
            <input
                placeholder="Add title"
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                disabled={isTeacher}
            />
          </div>
          {/*<div className="form-row">*/}
          <label>
            <FaLink/>
            <input
                type="text"
                name="link"
                value={formData.link}
                onChange={handleChange}
                required
                placeholder="Add link"

            />
          </label>
          <div className="form-row">
            <label>
              <PiStudentBold/>
              <input
                  list="student-list"
                  name="student"
                  value={formData.student}
                  onChange={handleChange}
                  required
                  placeholder="Add Student"
                  disabled={isTeacher}

              />
              <datalist id="student-list">
                {students
                    .filter((student) => student.status === "active")
                    .map((student) => (
                        <option key={student.id} value={student.name}/>
                    ))}
              </datalist>
            </label>
            {/*</div>*/}
            {/*<div className="form-row">*/}
            <label>
              <GiTeacher/>
              <input
                  list="teacher-list"
                  name="teacher"
                  value={formData.teacher}
                  onChange={handleChange}
                  required
                  placeholder="Add Teacher"
                  disabled={isTeacher}

              />
              <datalist id="teacher-list">
                {teachers
                    .filter((teacher) => teacher.status === "active")
                    .map((teacher) => (
                        <option key={teacher.id} value={teacher.name}/>
                    ))}
              </datalist>
            </label>
          </div>
          <div className="form-row">
            <label>
              <MdDateRange/>
              <DatePicker
                  selected={formData.date}
                  onChange={handleDateChange}
                  required
              />
            </label>
            {/*</div>*/}
            {/*<div className="form-row">*/}
            <label>
              <FaBook/>
              <input
                  list="subject-list"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  placeholder="Add subject"
                  disabled={isTeacher}

              />
              <datalist id="subject-list">
                {subjects.map((subject) => (
                    <option key={subject._id} value={subject.subjectName}/>
                ))}
              </datalist>
            </label>
          </div>
          <div className="form-row">

            <label>
              <RiParentFill/>
              <input
                  type="text"
                  name="studentConsultant"
                  value={formData.studentConsultant}
                  onChange={handleChange}
                  required
                  placeholder="Add Student Consultant"
                  list="studentConsultant-list"
                  disabled={isTeacher}

              />
              <datalist id="studentConsultant-list">
                {studentConsultants.map((studentConsultant) => (
                    <option
                        key={studentConsultant._id}
                        value={studentConsultant.name}
                    />
                ))}
              </datalist>
            </label>

            {/*<label>*/}
            {/*  <FaSchool/>*/}
            {/*  <input*/}
            {/*      list="grade-list"*/}
            {/*      type="text"*/}
            {/*      name="grade"*/}
            {/*      placeholder="Add grade"*/}
            {/*      value={formData.grade}*/}
            {/*      onChange={handleChange}*/}
            {/*      required*/}
            {/*      disabled={isTeacher}*/}

            {/*  />*/}
            {/*  <datalist id="grade-list">*/}
            {/*    {grades.map((grade) => (*/}
            {/*        <option key={grade._id} value={grade.grade}/>*/}
            {/*    ))}*/}
            {/*  </datalist>*/}
            {/*</label>*/}

            {(user.role === 1000 || user.role === 3000) && (
                <label>
                  <FaMoneyCheckAlt/>
                  <input
                      type="text"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      required
                      placeholder="Add price"
                  />
                </label>
            )}
          </div>


          <div className="form-row">
            <label>
              Start Time:
              <input
                  type="time"
                  name="startTime"
                  className="time-input"
                  value={formData.startTime}
                  onChange={(e) => handleTimeChange(e, "startTime")}
                  required
              />
            </label>
            <label>
              End Time: &nbsp;
              <input
                  type="time"
                  name="endTime"
                  className="time-input"
                  value={formData.endTime}
                  onChange={(e) => handleTimeChange(e, "endTime")}
                  required
              />
            </label>
          </div>
          <label>
            Remark: &nbsp;
            <textarea
                cols={50}
                type="text"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
                placeholder="Add remark"
                disabled={isTeacher}

            />
          </label>
          <div className="form-buttons">
            <button type="submit">Update</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEventModel;
