import { Login } from "./view/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminPanel from "./view/AdminPanel";
import ProtectedRoute from "./auth/protectedRoute";
import {AuthProvider} from "./auth/AuthContext";
import Zoom from "./view/zoom/zoom";
import ForgetPassword from '../src/view/forgetPassword/ForgetPassword'

function App() {
    const token = localStorage.getItem('token');

    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path='/' element={token ? <AdminPanel/> : <Login/>} />
                        <Route path='/adminPanel' element={<ProtectedRoute element={<AdminPanel />} />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
