import { Parser } from "@json2csv/plainjs";
import React, { useState, useEffect } from "react";
import { Loader } from "../loader/Loader";
import { IoSearch } from "react-icons/io5";
import Popup from "../popup/Popup";
import { months } from "../../utils/helperFunctions/months";
import FilterComponent from "./FilterComponent";
const AllClasses = ({
  classes,
  employees,
  grades,
  subjects,
  students,
  user,
}) => {
  const [filters, setFilters] = useState({
    teachers: [],
    students: [],
    grades: [],
    subjects: [],
    months: [],
    years: [],
  });
  const [selectedItems, setSelectedItems] = useState(new Set()); // Track selected items
  const [sortOrder, setSortOrder] = useState("asc");
  const [collapsedSections, setCollapsedSections] = useState({
    teachers: true,
    students: true,
    grades: true,
    subjects: true,
    months: true,
    years: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  const years = Array.from(
    new Set(classes.map((cls) => new Date(cls.date).getFullYear()))
  );

  useEffect(() => {
    console.log("Filters updated:", filters);
  }, [filters]);

  const getStudentById = (stuId) => {
    return (
      students.find((student) => student.stuId === stuId) || {
        name: "Unknown Student",
        type: "Unknown",
      }
    );
  };

  const filterClasses = () => {
    if (!user || !user.role) {
      return classes;
    }
    if (user.role === 3004) {
      return classes.filter((course) => course.studentEmail === user.email);
    } else if (user.role === 3002) {
      return classes.filter((course) => course.teacherEmail === user.email);
    } else if (user.role === 3003) {
      return classes.filter(
        (course) => course.studentConsultantEmail === user.email
      );
    } else {
      return classes;
    }
  };

  const filteredClasses = filterClasses();

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType].includes(value)) {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (v) => v !== value
        );
        console.log("filterby", updatedFilters);
      } else {
        updatedFilters[filterType] = [...updatedFilters[filterType], value];
      }
      return updatedFilters;
    });
  };

  const handleTagRemove = (filterType, value) => {
    handleFilterChange(filterType, value);
  };

  const isFiltered = (item) => {
    const itemDate = new Date(item.date);
    const itemMonth = itemDate.getMonth();
    const itemYear = itemDate.getFullYear();
    return (
      (!filters.teachers.length || filters.teachers.includes(item.teacher)) &&
      (!filters.students.length ||
        filters.students.includes(getStudentById(item.student).name)) &&
      (!filters.grades.length || filters.grades.includes(item.grade)) &&
      (!filters.subjects.length || filters.subjects.includes(item.subject)) &&
      (!filters.months.length || filters.months.includes(months[itemMonth])) &&
      (!filters.years.length || filters.years.includes(itemYear)) &&
      (!searchQuery === "" ||
        getStudentById(item.student)
          .name.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.student.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const sortedClasses = [...filteredClasses.filter(isFiltered)].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const toggleSection = (section) => {
    setCollapsedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleCheckboxChange = (classId, studentName) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(classId)) {
        newSelected.delete(classId);
      } else {
        newSelected.add(classId);
      }
      return newSelected;
    });

    if (selectedItems.has(classId)) {
      setFilters((prevFilters) => {
        const updatedStudents = prevFilters.students.filter(
          (name) => name !== studentName
        );
        return {
          ...prevFilters,
          students: updatedStudents,
        };
      });
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        students: [...prevFilters.students, studentName],
      }));
    }
  };

  const downloadCSV = () => {
    const filteredClasses = classes.filter(isFiltered);

    if (filteredClasses.length === 0) {
      setPopupText("Please select fields to download.");
      setShowPopup(true);
      return;
    }

    const sanitizedClasses = filteredClasses.map(
      ({ _id, __v, ...rest }) => rest
    );

    const parser = new Parser();

    const csv = parser.parse(sanitizedClasses);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "filtered_classes.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  if (!classes || !subjects || !grades || !employees || !user || !students) {
    return <Loader />;
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const student = students.find(
      (student) => student.name.toLowerCase() === searchQuery.toLowerCase()
    ) || { name: searchQuery };
    if (student) {
      const studentName = student.name;
      setSelectedItems((prev) => new Set(prev.add(student.stuId)));
      handleFilterChange("students", studentName);
      setSearchQuery("");
      console.log("search", studentName);
    }
  };

 
  const filterData = {
    teachers: employees.filter((emp) => emp.role === 3002).map(emp => emp.name),
    students: students.map(stu => stu.name),
    grades: grades.map(grade => grade.grade),
    subjects: subjects.map(subject => subject.subjectName),
    months: months,
    years: years,
  };

  const sectionTitles = {
    teachers: "Tutors",
    students: "Students",
    grades: "Grades",
    subjects: "Subjects",
    months: "Months",
    years: "Years"
  };

  

  return (
    <div className="all-classes-container">
      <FilterComponent
        filterData={filterData}
        filters={filters}
        collapsedSections={collapsedSections}
        handleFilterChange={handleFilterChange}
        toggleSection={toggleSection}
        sectionTitles={sectionTitles}
      />
      <div className="output-container">
        <div className="selected-filters">
          {Object.keys(filters).map((filterType) =>
            filters[filterType].map((filterValue, index) => (
              <div
                className="filter-tag"
                key={`${filterType}-${filterValue}-${index}`}
                onClick={() => handleTagRemove(filterType, filterValue)}
              >
                {filterValue} <span className="remove-tag">x</span>
              </div>
            ))
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="search-bar">
            <form onSubmit={handleSearchSubmit}>
              <input
                type="text"
                placeholder="Search Students..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearchSubmit(e);
                  }
                }}
              />
              <IoSearch className="icon" />
              <button type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form>
          </div>
          <div className="download-btn-container">
            {(user.role === 1000 ||
              user.role === 3000 ||
              user.role === 3001) && (
              <button onClick={downloadCSV}>Download CSV</button>
            )}
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>Subject</th>
              <th>Tutor</th>
              <th>Student</th>
              <th>Grade</th>
              <th onClick={toggleSortOrder} style={{ cursor: "pointer" }}>
                Date {sortOrder === "asc" ? "▲" : "▼"}
              </th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {sortedClasses.map((cls) => {
              const student = getStudentById(cls.student);
              return (
                <tr key={cls.classId}>
                  <td>{cls.subject}</td>
                  <td>{cls.teacher}</td>
                  <td>{student.name}</td>
                  <td>{cls.grade}</td>
                  <td>{new Date(cls.date).toLocaleDateString()}</td>
                  <td>{cls.startTime}</td>
                  <td>{cls.endTime}</td>
                  <td>
                    <a
                      href={cls.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPopup && (
        <Popup
          text={popupText}
          show={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default AllClasses;
