import image1 from "../../../assets/bookImage.png";
import React from "react";

export const MyCourses = ({ courses, user }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    const isToday = (dateString) => {
        const today = new Date();
        const date = new Date(dateString);
        return (
            date.getUTCDate() === today.getUTCDate() &&
            date.getUTCMonth() === today.getUTCMonth() &&
            date.getUTCFullYear() === today.getUTCFullYear()
        );
    };

    const filterCourses = () => {
        let filtered = courses;

        if (user.role === 3004) {
            filtered = filtered.filter((course) => course.studentEmail === user.email);
        } else if (user.role === 3002) {
            filtered = filtered.filter((course) => course.teacherEmail === user.email);
        } else if (user.role === 3003) {
            filtered = filtered.filter((course) => course.studentConsultantEmail === user.email);
        }

        // Filter courses to show only today's upcoming classes
        filtered = filtered.filter((course) => isToday(course.date));

        return filtered;
    };

    const filteredCourses = filterCourses();
    return (
        <div className="dashboard-component">
            {(user.role === 3000 || user.role === 3001) ? (
                <h2>Upcoming Classes</h2>
            ) : (
                <h2>Upcoming Classes</h2>
            )}
            <div className="my-courses">
                <div className="course-header">
                    <div className="course-name-text">Subject</div>
                    <div className="columns">
                        {(user.role === 1000 ||user.role === 3000 || user.role === 3001 || user.role === 3004) && (
                            <div>Tutor</div>
                        )}
                        {(user.role === 1000 ||user.role === 3000 || user.role === 3001 || user.role === 3002) && (
                            <div>Student</div>
                        )}
                        <div>Grade</div>
                        <div>Date</div>
                        <div>Link</div>
                    </div>
                </div>
                <div className="course-list">
                    {filteredCourses.map((course, index) => (
                        <div key={index} className="each-my-course">
                            <div className="course-info">
                                <img src={image1} alt={course.subject} className="image" />
                                <div>
                                    <h3>{course.subject}</h3>
                                </div>
                            </div>
                            <div className="columns">
                                <div>{course.teacher}</div>
                                <div>{course.student}</div>
                                <div>{course.grade}</div>
                                <div>{formatDate(course.date)}</div>
                                <div>
                                    <a
                                        href={course.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Link
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MyCourses;
