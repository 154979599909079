import React, { useState, useEffect } from 'react';

const Popup = ({ show, text, onClose }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    let timeout;
    if (show) {
      setVisible(true);
      timeout = setTimeout(() => {
        closePopup();
      }, 3000); 
    } else {
      setVisible(false);
    }
    return () => clearTimeout(timeout);
  }, [show]);

  const closePopup = () => {
    setVisible(false);
    onClose();
  };

  if (!visible) return null;

  return (
    <div className="popup" onClick={closePopup}>
      <p>{text}</p>
    </div>
  );
};

export default Popup;
