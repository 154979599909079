import React, {useState, useEffect} from "react";
import Student from "./students/Student";
import {Employee} from "./employees/Employee";
import {FaSearch} from "react-icons/fa";
import {Loader} from "../loader/Loader";
import SearchContainer from "../searchBar/SearchContainer"; 


function DataSetUp({subjects, grades, employees, students, user}) {
    const [activeTab, setActiveTab] = useState("employee");
    const [searchTerm, setSearchTerm] = useState("");
    const [employeeData, setEmployeeData] = useState([]);
    const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [filteredStudentData, setFilteredStudentData] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [loading, setLoading] = useState(true); // Initialize loading state

    useEffect(() => {
        if (employees && students) {
            const filteredEmployees = employees.filter(employee => employee.role !== 1000);
            setEmployeeData(filteredEmployees);
            setFilteredEmployeeData(employees);
            setStudentData(students);
            setFilteredStudentData(students);
            setLoading(false);
        }
    }, [employees, students]);

    useEffect(() => {
        if (!loading) {
            const filtered = employeeData.filter((employee) => {
                const subjects = employee.subjects
                    .map((subject) => subject.name)
                    .join(", ");
                return (
                    employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    subjects.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    employee.email.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFilteredEmployeeData(filtered);
        }
    }, [searchTerm, employeeData, loading]);

    useEffect(() => {
        if (!loading) {
            const filtered = studentData.filter((employee) => {
                const subjects = employee.subjects
                    .map((subject) => subject.name)
                    .join(", ");
                return (
                    employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    subjects.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    employee.email.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFilteredStudentData(filtered);
        }
    }, [searchTerm, studentData, loading]);

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleOptionSelect = (option) => {
        setActiveTab(option);
        setDropdownOpen(false);
    };

    if (loading) {
        return <Loader/>;
    }


    const studentBasedUser= user &&     user.role === 3002
        ? students.filter((student) => user.assigned.includes(student.email))
        : students;

    const employeeBasedUser= user &&   user.role === 3002
        ? employees.filter(
            (employee) => employee.role === 3000 || employee.role === 3001
        )
        : employees;

    return (
        <div className={`dataSetup ${isSidePanelOpen ? "with-overlay" : ""}`}>
            <div className="first-col">
                {activeTab === "student" && <h2>Student Information</h2>}
                {activeTab === "employee" && <h2>Employee Information</h2>}
                <div className="tabs">
                <SearchContainer 
                    searchTerm={searchTerm} 
                    setSearchTerm={setSearchTerm} 
                />
                    <div className="custom-dropdown">
                        <div className="dropdown-toggle" onClick={handleDropdownToggle}>
                            {activeTab === "student" ? (
                                <div className="toggle-option-with-count">
                                    Student
                                    <div className="active-count">
                                        <p>{studentBasedUser.filter(student => student.status === "active").length}</p>
                                        <div className="active-light"></div>
                                    </div>
                                </div>
                            ) : <div className="toggle-option-with-count">
                                Employee
                                <div className="active-count">
                                    <p>{employeeBasedUser.filter(employee => employee.status === "active" && employee.role !== 1000).length}</p>
                                    <div className="active-light"></div>
                                </div>
                            </div>}
                        </div>
                        {dropdownOpen && (
                            <div className="dropdown-options">
                                <div
                                    className="dropdown-option"
                                    onClick={() => handleOptionSelect("student")}
                                >
                                Student
                                </div>
                                <div
                                    className="dropdown-option"
                                    onClick={() => handleOptionSelect("employee")}
                                >
                                    Employee
                                </div>
                            </div>
                        )}
                    </div>
                </div>

          <div className="tab-content">
            {activeTab === "student" && (
                <Student
                    subjects={subjects}
                    grades={grades}
                    students={filteredStudentData}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                    setIsLoading={setLoading}
                    user={user}
                />
            )}
            {activeTab === "employee" && (
                <Employee
                    subjects={subjects}
                    grades={grades}
                    employees={filteredEmployeeData}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                    setIsLoading={setLoading}
                    user={user}
                    students={filteredStudentData}
                />
            )}
          </div>
        </div>
      </div>
  );
}

export default DataSetUp;
