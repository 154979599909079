import axios from 'axios';

const apiPort = process.env.REACT_APP_PORT_KEY;

const baseURL = `${window.location.origin.includes("http://localhost:") ? apiPort : window.location.origin}/smartedu-api`
// const  baseURL="https://smart-edu-server-beta.vercel.app/smartedu-api"
console.log(baseURL)
export const axiosInstance = axios.create({
    baseURL
});

console.log("API PORT", apiPort)
console.log("baseURL", baseURL)

